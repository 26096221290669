import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import AuthContextProvider from "./Context/AuthContext";
import LoadingContextProvider from "./Context/LoadingContext";
import "react-toastify/dist/ReactToastify.css";
import TaxFilerStatusContextProvider from "./Context/TaxFilerStatusContext";

// "start": "export HTTPS=true && react-scripts start",

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <ToastContainer />
        <BrowserRouter>
            <LoadingContextProvider>
                <AuthContextProvider>
                    <TaxFilerStatusContextProvider>
                        <App />
                    </TaxFilerStatusContextProvider>
                </AuthContextProvider>
            </LoadingContextProvider>
        </BrowserRouter>
    </>
);
