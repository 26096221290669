import styled from "styled-components";

export const Container = styled.div`
    padding: 0 2.5rem;
    margin-bottom: 10rem;
`;

export const FeatureHeader = styled.div`
    font-weight: 700;
    letter-spacing: 0.1rem;
`;

export const Header = styled.div`
    display: flex;
    font-weight: 700;
    letter-spacing: 0.1rem;
    font-size: 4rem;
    font-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
    & span {
        background: linear-gradient(
            45deg,
            #55bb53 0%,
            #0d71ba 80.42%,
            #0d71ba 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        font-size: 3rem;
     }
     @media screen and (max-width: 1025px) {
        flex-direction: column;
        font-size: 3rem;
     }
     @media screen and (max-width: 481px) {
        flex-direction: column;
        font-size: 2rem;
     }
`;



export const Content = styled.div`
    max-width: 120rem;
    margin: auto;
    display: flex;
    // grid-template-areas: "feature1 feature2 feature3";
    // grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 980px) {
        flex-direction: column;
    }
    @media screen and (max-width: 640px) {
       flex-direction: column;
    }
`;

export const Feature = styled.div`
    flex: 1;
    flex-direction: column;
    padding: 3rem 1.6rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
    display: flex;
    gap: 2rem;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s, -webkit-box-shadow 0.3s;
    color: #60626d;
    align-items: center;
    &:hover {
        background: #0b2a41;
        color: #fff;
    }
`;

export const FeatureIcon = styled.img`
    width: 7rem;
    height: 7rem;
`;

export const FeatureContent = styled.div`
    letter-spacing: 0.1rem;
    text-align: center;
`;
