import React, { useEffect, useState, useContext } from "react";
import {
  changePostPaymentFileTaxesDetails,
  postPaymentDocumentsUpload,
} from "../../../Api/filetaxes.api";
import PayNow from "../../../Components/Dashboard/Home/PayNow/PayNow";
import Stepper from "../../../Components/Global/Stepper/Stepper";
import { TaxFilerStatusContext } from "../../../Context/TaxFilerStatusContext";
import { AiFillCreditCard, AiOutlineCloudUpload } from "react-icons/ai";
import { BsInputCursorText } from "react-icons/bs";
import { BiFileFind } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Container, Content, ForwardBackwardButtons } from "./styles";
import EnterDetails from "../../../Components/Dashboard/Home/EnterDetails/EnterDetails";
import Button from "../../../Components/Global/Button/Button";
import UploadDocuments from "../../../Components/Dashboard/Home/UploadDocuments/UploadDocuments";
import Review from "../../../Components/Dashboard/Home/Review/Review";
import {
  generatePresignedUrls,
  uploadFileFromPresignedUrl,
} from "../../../Api/presignedurl.api";
import { LoadingContext } from "../../../Context/LoadingContext";

const UserHome = () => {
  const [statusOfTaxFiling, fetchTaxFilingStatus] = useContext(
    TaxFilerStatusContext
  );
  const [activeStep, setActiveStep] = useState(0);
  const [allowToContinue, setAllowToContinue] = useState(false);
  const [data, setData] = useState(null);
  const [, startApiCall, finishApiCall] = useContext(LoadingContext);
  useEffect(() => {
    if (statusOfTaxFiling) {
      if (!statusOfTaxFiling.documents_uploaded) setActiveStep(0);
      else if (!statusOfTaxFiling.is_paid) setActiveStep(1);
      else setActiveStep(2);
    }
  }, [statusOfTaxFiling]);
  useEffect(() => {
    setAllowToContinue(false);
    setData(null);
  }, [activeStep]);
  const handleSubmit = () => {
    setActiveStep(1);
  };
  const steps = [
    {
      icon: <BsInputCursorText />,
      name: "Enter Details",
      content: <EnterDetails handleSubmit={handleSubmit} />,
      //   next: async () => {
      //     startApiCall();
      //     await changePostPaymentFileTaxesDetails({
      //       requestId: statusOfTaxFiling.id,
      //       questions: data.filter((question) => question.answer),
      //     });
      //     finishApiCall();
      //   },
    },
    // {
    //   icon: <AiFillCreditCard />,
    //   name: "Payment",
    //   content: <PayNow />,
    // },
    // {
    //     icon: <AiOutlineCloudUpload />,
    //     name: "Upload Documents",
    //     content: <UploadDocuments />,
    //     next: async () => {
    //         startApiCall();
    //         const requestData = {
    //             requestId: statusOfTaxFiling.id,
    //             documents: [],
    //         };
    //         const presignedUrlsData = [];
    //         data.forEach(({ file }) => {
    //             if (file) {
    //                 const splittedName = file.name.split(".");
    //                 presignedUrlsData.push({
    //                     extension: splittedName[splittedName.length - 1],
    //                     contentType: file.type,
    //                 });
    //             }
    //         });
    //         const { data: presignedUrls } = await generatePresignedUrls(
    //             presignedUrlsData
    //         );
    //         for (const [index, { id, file }] of data.entries()) {
    //             if (file) {
    //                 requestData.documents.push({
    //                     id,
    //                     key: presignedUrls[index].key,
    //                 });
    //                 await uploadFileFromPresignedUrl({
    //                     upload_url: presignedUrls[index].url,
    //                     file,
    //                     fileType: file.type,
    //                 });
    //             }
    //         }
    //         await postPaymentDocumentsUpload(requestData);
    //         finishApiCall();
    //     },
    // },
    {
      icon: <BiFileFind />,
      name: "Review",
      content: <Review />,
    },
  ];
  const activeStepContent = React.cloneElement(steps[activeStep].content, {
    setAllowToContinue,
    data,
    setData,
    statusOfTaxFiling,
  });
  // if (!statusOfTaxFiling) return null;
  return (
    <Container>
      {/* <Stepper steps={steps} activeStep={activeStep} /> */}
      <Content>{activeStepContent}</Content>
      {activeStep !== 0 && activeStep !== steps.length - 1 && (
        <ForwardBackwardButtons>
          <Button
            isDisabled={activeStep === 1}
            hoverBg="#f5f5f5"
            hoverColor="#0b2a41"
            onClick={() => setActiveStep((activeStep) => activeStep - 1)}
            padding="1rem 2rem"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <IoIosArrowBack fontSize={20} />
            Back
          </Button>
          <Button
            isDisabled={!allowToContinue}
            hoverBg="#f5f5f5"
            hoverColor="#0b2a41"
            onClick={async () => {
              if (steps[activeStep].next) await steps[activeStep].next();
              setActiveStep((activeStep) => activeStep + 1);
              fetchTaxFilingStatus();
            }}
            padding="1rem 2rem"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            Continue
            <IoIosArrowForward fontSize={20} />
          </Button>
        </ForwardBackwardButtons>
      )}
    </Container>
  );
};

export default UserHome;
