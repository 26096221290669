import styled from "styled-components";

export const Container = styled.div`
    max-width: 120rem;
    margin: auto;
    margin-top: 10rem;
    padding: 0 2.5rem;
`;

export const Heading = styled.div`
    color: #0b2a41;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 4.5rem;
    text-align: center;
    & span {
        background: linear-gradient(
            45deg,
            #55bb53 0%,
            #0d71ba 80.42%,
            #0d71ba 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width: 700px) {
        font-size: 3rem;
    }
`;

export const Content = styled.div`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (max-width: 700px) {
        gap: 0;
    }
`;

export const Service = styled.div`
    text-align: center;
    width: 45%;
    flex-shrink: 0;
    text-align: justified;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    @media screen and (max-width: 900px) {
        width: 40%;
    }
    @media screen and (max-width: 770px) {
        width: 50%;
    }
    @media screen and (max-width: 700px) {
        width: 100%;
    }
    border-radius: 1rem;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 8%);
    gap: 2rem;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s, -webkit-box-shadow 0.3s;
    padding:  2rem;
`;

export const ServiceIcon = styled.img`
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
    /* @media screen and (max-width: 400px) {
        margin: auto;
    } */
`;

export const ServiceHeading = styled.div`
    line-height: 130%;
    font-size: 2rem;
    font-weight: 600;
    color: #0b2a41;
`;

export const ServiceDesc = styled.div`
    font-weight: 500;
    line-height: 1.5;
    color: #60626d;
    text-align: center;
`;
