import styled from "styled-components";

export const PricingContainer = styled.div`
  padding: 3rem 3rem;
  margin-bottom: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 1rem;
  }
`;

export const Text = styled.div`
@media screen and (max-width: 480px) {
  font-size: 0.9rem;
  padding: 0.5rem;
}
@media screen and (max-width: 1200px) {
  font-size: 0.9rem;
  padding: 0.5rem;

}
`;


export const FeatureSubText = styled.div`
  padding: 1rem 0rem;
  @media screen and (max-width: 480px) {
    padding: 1rem 0rem;
  }
  `;

export const ContentContainer = styled.div`
max-width: 120rem;
margin: auto;
display: flex;
`;

export const StartFilingButton = styled.div`
  text-align: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);
  cursor: pointer;
  width: 100%;
  background: #55bb53;
  color: #fff;
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }
`;

export const FeatureHeader = styled.div`
  font-weight: 700;
  letter-spacing: 0.1rem;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 1rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
    padding: 1rem;
  }
`;

export const CutPrice = styled.div`
  text-decoration: line-through;
  color: #ff0000;
  font-size: 1.5rem;
  font-weight: 700;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }
`;

export const Price = styled.div`
  font-size: 3rem;
  font-weight: 700;
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

export const Content = styled.div`
  padding: 3rem 1.6rem;
  margin: auto;
  display: flex;
  gap: 3rem;
  @media screen and (max-width: 980px) {
    flex-direction: row;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const FeatureImage = styled.img`
  width: 100%;
  height: 15rem;
  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const Feature = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 8%);
  gap: 1rem;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  color: #60626d;
  align-items: center;
  @media screen and (max-width: 1050px) {
    flex-grow: none;
    flex: 1;
    width: 200px;
    margin: 1rem;
  }

  @media screen and (max-width: 820px) {
    width: 180px;
    flex-wrap: wrap;
    margin: 1rem;
    padding: 1rem;
  }
  @media screen and (max-width: 1200px) {
    width: 200px;
  }
  @media screen and (max-width: 480px) {
    width: 250px;
    padding: 1rem;
    margin: 2rem;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
    padding: 1rem;
    margin: 1.5rem;
  }
`;

export const FeatureIcon = styled.img`
  width: 7rem;
  height: 7rem;
`;

export const FeatureContent = styled.div`
  letter-spacing: 0.1rem;
  text-align: center;
`;

export const Container = styled.div`
  margin-bottom: 5rem;
  width: 70%;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  table,
  td,
  th {
    border: none;
  }
  border-radius: 0.5rem;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
  display: flex;
  gap: 2rem;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Logo = styled.img`
  width: 10rem;
  /* user-select: none;
    font-weight: bold !important;
    color: #0d71ba !important;
    background: linear-gradient(
        45deg,
        #55bb53 0%,
        #0d71ba 80.42%,
        #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    display: flex;
    align-items: center; */
`;

export const Desc = styled.div`
  line-height: 1.8;
  font-weight: 400;
  font-size: 2rem;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  width: 80%;
  padding: 0 1.5rem;
  text-align: justify;
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 1rem 0 1rem 0;
  text-align: center;
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin: 5rem 0 0 0;
  margin-bottom: 4rem;
`;
