import styled from "styled-components";

export const Container = styled.div`
    padding: 0 3rem;
    margin-top: 10rem;
    @media screen and (max-width: 920px) {
        margin-top: 7rem;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    max-width: 120rem;
    margin: auto;
    @media screen and (max-width: 760px) {
        flex-direction: column;
        text-align: center;
        gap: 0rem;
    }
`;

export const HeadingDesc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    width: 50%;
    @media screen and (max-width: 920px) {
        gap: 2.5rem;
    }
    @media screen and (max-width: 760px) {
        width: 100%;
    }
`;

export const Heading = styled.div`
    line-height: 120%;
    font-size: 4.5rem;
    font-weight: 700;
    color: #0b2a41;
    @media screen and (max-width: 920px) {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 500px) {
        font-size: 2.5rem;
        line-height: 150%;
    }
`;

export const Desc = styled.div`
    color: #5f5f5f;
    font-size: 2rem;
    line-height: 30px;
    @media screen and (max-width: 920px) {
        font-size: 1.8rem;
    }
    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`;

export const Image = styled.div`
    width: 50%;
    @media screen and (max-width: 760px) {
        width: 80%;
    }
    @media screen and (max-width: 430px) {
        width: 100%;
    }
    @media screen and (max-width: 760px) {
        margin-top: -3rem;
    }
`;
