import React from "react";
import { useEffect } from "react";
import { Container } from "./styles";
import JotformEmbed from "react-jotform-embed";
const EnterDetails = ({
  data: questions,
  setData: setQuestions,
  statusOfTaxFiling,
  setAllowToContinue,
  autoResize,
  autoFocus,
  handleSubmit
}) => {

  useEffect(() => {
    const handleMessages = (content) => {
      if (!content) {
        return;
      }
      console.log({content: content?.data})
      if (
        typeof content.data === "object" &&
        content.data.action === "submission-completed"
      ) {
        handleSubmit();
        return;
      }
      return;
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleMessages, true); // Capture
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleMessages);
    }
    return () => {
      if (window.removeEventListener) {
        window.removeEventListener("message", handleMessages, true);
      }
    };
  }, []);

  return (
    <Container>
      <JotformEmbed
        src="https://form.jotform.com/230284826678467"
        onSubmit={handleSubmit}
        scrolling={true}
      />
    </Container>
  );
};

export default EnterDetails;
