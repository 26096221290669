import styled from "styled-components";

export const Container = styled.div`
    max-width: 122.5rem;
    margin: 10rem auto;
    padding: 0 2.5rem;
`;

export const Heading = styled.div`
    text-align: center;
    font-size: 4.8rem;
    font-weight: bold;
    margin-bottom: 4rem;
`;

export const Content = styled.div``;

export const PrivacyPolicyPoint = styled.div``;

export const PrivacyPolicyHeading = styled.div`
    text-transform: uppercase;
    font-weight: 600;
`;

export const PrivacyPolicyContent = styled.div`
    margin: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
`;

export const PrivacyPolicySubHeading = styled.div`
    text-transform: capitalize;
    font-weight: bold;
`;

export const PrivacyPolicyParagraph = styled.p``;
