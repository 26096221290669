import styled from "styled-components";

export const Container = styled.div``;

export const Chat = styled.div`
  min-height: 0;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
`;
