import React, { useEffect, useState } from "react";
import { Container, InputGroup } from "./styles";
import { Table, TableRow, Td, Th } from "../../../globalStyles";
import {
    createNewDocument,
    editDocument,
    getAllDocuments,
    updateDocumentStatus,
    updateDocumentOptionalStatus,
} from "../../../Api/documents.api";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { AiFillEdit } from "react-icons/ai";
import Modal from "../../../Components/Global/Modal/Modal";
import Input from "../../../Components/Global/Input/Input";

const TaxFilingDocuments = () => {
    const [documents, setDocuments] = useState([]);
    const [document, setDocument] = useState({
        id: null,
        name: "",
        desc: "",
    });
    const [showEditNewDocumentModal, setShowEditNewDocumentModal] = useState({
        state: false,
        for: null,
    });
    const getAndSetAllDocuments = () =>
        getAllDocuments().then(({ data }) => setDocuments(data));
    const modalOnSubmit = async () => {
        if (showEditNewDocumentModal.for === "edit")
            await editDocument(document);
        else await createNewDocument(document);
        setShowEditNewDocumentModal(false);
        getAndSetAllDocuments();
    };
    useEffect(() => {
        document.title = "Dashboard - Tax Filing Documents • Eazytaxes";
        getAndSetAllDocuments();
    }, []);
    return (
        <Container>
            {showEditNewDocumentModal.state && (
                <Modal
                    heading={
                        showEditNewDocumentModal.for === "edit"
                            ? "Edit Document"
                            : "Create Document"
                    }
                    onClose={() => setShowEditNewDocumentModal(false)}
                    onSubmit={modalOnSubmit}
                    style={{ maxWidth: "60rem", width: "100%" }}
                    buttonProps={{
                        isDisabled:
                            !Boolean(document.name) || !Boolean(document.desc),
                    }}
                    buttonText={
                        showEditNewDocumentModal.for === "edit"
                            ? "save"
                            : "create"
                    }
                >
                    <InputGroup>
                        <Input
                            placeholder="Document"
                            value={document.name}
                            onChange={({ target }) =>
                                setDocument({
                                    ...document,
                                    name: target.value,
                                })
                            }
                        />
                        <Input
                            placeholder="Desc"
                            value={document.desc}
                            onChange={({ target }) =>
                                setDocument({
                                    ...document,
                                    desc: target.value,
                                })
                            }
                        />
                    </InputGroup>
                </Modal>
            )}
            <Table>
                <TableRow>
                    <Th>Id</Th>
                    <Th>Document</Th>
                    <Th>Desc</Th>
                    <Th>Active</Th>
                    <Th>Optional</Th>
                    <Th>Edit</Th>
                </TableRow>
                {documents.map(({ id, name, desc, is_active, is_optional }) => (
                    <TableRow key={id}>
                        <Td>{id}</Td>
                        <Td>{name}</Td>
                        <Td>{desc}</Td>
                        <Td>
                            <Toggle
                                defaultChecked={is_active}
                                onChange={async ({ target }) => {
                                    await updateDocumentStatus({
                                        id,
                                        active: target.checked,
                                    });
                                    getAndSetAllDocuments();
                                }}
                            />
                        </Td>
                        <Td>
                            <Toggle
                                defaultChecked={is_optional}
                                onChange={async ({ target }) => {
                                    await updateDocumentOptionalStatus({
                                        id,
                                        optional: target.checked,
                                    });
                                    getAndSetAllDocuments();
                                }}
                            />
                        </Td>
                        <Td
                            style={{
                                cursor: "pointer",
                                fontSize: "2.5rem",
                            }}
                        >
                            <AiFillEdit
                                onClick={() => {
                                    setShowEditNewDocumentModal({
                                        state: true,
                                        for: "edit",
                                    });
                                    setDocument({
                                        id,
                                        name,
                                        desc,
                                    });
                                }}
                            />
                        </Td>
                    </TableRow>
                ))}
            </Table>
            <button
                onClick={() => {
                    setShowEditNewDocumentModal({
                        state: true,
                        for: "create",
                    });
                    setDocument({
                        document: "",
                        desc: "",
                    });
                }}
            >
                Add New Document
            </button>
        </Container>
    );
};

export default TaxFilingDocuments;
