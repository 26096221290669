import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import Input from "../Input/Input";

const CustomPhoneInput = React.forwardRef((props, ref) => {
    return (
        <Input
            ref={ref}
            placeholder="Phone Number"
            icon={<AiOutlinePhone />}
            {...props}
        />
    );
});

export default CustomPhoneInput;
