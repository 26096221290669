import React, { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BackArrow, Container, Form, Image } from "./styles";
import { BsArrowLeft } from "react-icons/bs";
import "@lottiefiles/lottie-player";

const images = {
    "/accounts/reset-password":
        "https://assets7.lottiefiles.com/packages/lf20_lz8wv7e7.json",
    "/accounts/login":
        "https://assets2.lottiefiles.com/packages/lf20_yupefrh2.json",
    "/accounts/signup":
        "https://assets9.lottiefiles.com/packages/lf20_0mohmgca.json",
    "/accounts/forgot-password":
        "https://assets8.lottiefiles.com/packages/lf20_mdhnmscc.json",
    "/accounts/verify":
        "https://assets7.lottiefiles.com/packages/lf20_vfsfhzxm.json",
};

const Accounts = () => {
    const lottiePlayer = useRef();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        lottiePlayer.current.load(images[pathname]);
    }, [pathname]);
    return (
        <Container>
            <BackArrow onClick={() => navigate(-1)}>
                <BsArrowLeft />
            </BackArrow>
            <Image>
                <lottie-player
                    ref={lottiePlayer}
                    autoplay
                    loop
                    src={images[pathname]}
                    mode="normal"
                    style={{
                        width: "80%",
                        margin: "auto",
                    }}
                ></lottie-player>
            </Image>
            <Form>
                <Outlet />
            </Form>
        </Container>
    );
};

export default Accounts;
