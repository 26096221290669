import React from "react";
import CountUp from "react-countup";
import {
  Container,
  Content,
  Desc,
  Heading,
  HeadingDesc,
  NumberCard,
  NumberCardIcon,
  NumberCardNumber,
  NumberCardNumberTitle,
  NumberCards,
  NumberCardTitle,
  Wrapper,
} from "./styles";

const NumbersDontLie = () => {
  const numberCardStacks = [
    [
      {
        icon: "https://cdn-icons-png.flaticon.com/512/6219/6219262.png",
        number: "3400",
        title: "Tax Returns Filed",
      },
      {
        icon: "https://cdn-icons-png.flaticon.com/512/1026/1026176.png",
        number: "100",
        currency: "$",
        showK: true,
        title: "Tax saved",
      },
    ],
    [
      {
        icon: "https://cdn-icons-png.flaticon.com/512/8163/8163243.png",
        number: "800",
        title: "Happy clients",
      },
      {
        icon: "https://cdn-icons-png.flaticon.com/512/3585/3585091.png",
        number: "200",
        currency: "$",
        showK: true,
        title: "Refunds issued",
      },
    ],
  ];

  return (
    <Wrapper>
      <Container>
        <HeadingDesc>
          <Heading>
            Numbers <br />
            don’t lie!
          </Heading>
          <Desc>
            eazytaxes.com is the eaziest way to file your taxes online. We'll
            guide you through the process step-by-step, and we'll make sure you
            get all the deductions and credits you're entitled to. We're also
            the fastest way to get your refund, and we're always up-to-date on
            the latest tax laws.
          </Desc>
        </HeadingDesc>
        <Content>
          {numberCardStacks.map((numberCardStack, index) => (
            <NumberCards key={index}>
              {numberCardStack.map(({ number, title, icon, showK, currency }, index) => (
                <NumberCard key={index}>
                  <NumberCardNumberTitle>
                    <NumberCardNumber>
                        {currency && <div>{currency}</div>}
                      <CountUp end={number} enableScrollSpy scrollSpyOnce />
                      {showK && <div>k</div>}
                      <div>+</div>
                    </NumberCardNumber>
                    <NumberCardTitle>{title}</NumberCardTitle>
                  </NumberCardNumberTitle>
                  <NumberCardIcon src={icon} />
                </NumberCard>
              ))}
            </NumberCards>
          ))}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default NumbersDontLie;
