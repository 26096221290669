const privacyPolicyPoints = [
    {
        heading: "general",
        content: [
            `eazytaxes.com ("eazytaxes.com " or "we" or "us" or "our") respects the privacy of everyone ("Sites' visitor" "user" or "you") that uses our website at https://eazytaxes.com.com, as well as other device or online applications related or connected thereto (collectively, the "Sites").`,
            `The following eazytaxes.com Privacy Notice ("Privacy Notice") is designed to inform you, as a user of the Sites, about the types of personal information that eazytaxes.com may gather about or collect from you in connection with your use of the Sites. It also is intended to explain the conditions under which eazytaxes.com uses and discloses that personal information, and your rights in relation to that personal information.`,
            `The Sites are hosted in the United States and is subject to U.S. state and federal law. If you are accessing our Sites from other jurisdictions, please be advised that you are transferring your personal information to us in the United States, and by using our Sites, you are agreeing to that transfer and use of your personal information in accordance with this Privacy Notice. You also agree to abide to the applicable laws of the State of California and U.S. federal law concerning your use of the Sites and your agreements with us. If your use of the Sites would be unlawful in your jurisdiction, please do not use the Sites.`,
            `Company Information`,
            <>
                eazytaxes.com
                <br />
                8278 De Palma St
                <br />
                Downey, CA 90241
                <br />
                Email: hello@eazytaxes.com.com
                <br />
                Phone: __________
                <br />
            </>,
        ],
    },
    {
        heading: "how we collect and use your personal information",
        content: [
            `eazytaxes.com gathers personal information from users of the Sites. When you browse our Sites, subscribe to our services or contact us through various social or web forms you are voluntarily sharing personal information with us. This personal information also includes various data that we collect automatically. This may be the user’s Internet Protocol (IP) address, operating system, browser type and the locations of the Sites the user views right before arriving at, while navigating and immediately after leaving the Sites. It may also include various technical aspects of user’s computer or browser and users browsing habits that are collected through cookies. eazytaxes.com may analyze various mentioned personal information gathered from or about users to help eazytaxes.com better understand how the Sites are used and how to make them better. By identifying patterns and trends in usage, eazytaxes.com is able to better design the Sites to improve users’ experiences, both in terms of content and ease of use. From time to time, eazytaxes.com may also release the anonymized information gathered from the users in the aggregate, such as by publishing a report on trends in the usage of the Sites.`,
            `When we believe disclosure is appropriate, we may disclose your information to help investigate, prevent or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of eazytaxes.com , our users, our employees or others; to comply with applicable law or cooperate with law enforcement; to enforce our Terms of Use or other agreements or policies, in response to a subpoena or similar investigative demand, a court order or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us. We will notify you if permitted before undertaking such disclosures.`,
            `eazytaxes.com reserves the right to transfer all information in its possession to a successor organization in the event of a merger, acquisition, bankruptcy or other sale of all or a portion of eazytaxes.com ’s assets. Other than to the extent ordered by a bankruptcy or other court, the use and disclosure of all transferred information will be subject to this Privacy Notice, or to a new privacy notice if you are given notice of that new privacy notice and are given an opportunity to affirmatively opt-out of it.`,
        ],
    },
    {
        heading: "do we use cookies?",
        content: [
            `A “Cookie” is a string of information which assigns you a unique identification that a website stores on a user’s computer, and that the user’s browser provides to the website each time the user submits a query to the website. We use cookies on the Sites to keep track of services you have used, to record registration information regarding your login name and password, to record your user preferences, to keep you logged into the Sites and to facilitate purchase procedures. eazytaxes.com also uses Cookies to track the pages that users visit during each of the Sites’ sessions, both to help eazytaxes.com improve users’ experiences and to help eazytaxes.com understand how the Sites is being used. As with other personal information gathered from users of the Sites, eazytaxes.com analyzes and discloses in aggregated form information gathered using Cookies, so as to help eazytaxes.com , its partners and others better understand how the Sites is being used. SITES’ USERS WHO DO NOT WISH TO HAVE COOKIES PLACED ON THEIR COMPUTERS SHOULD SET THEIR BROWSERS TO REFUSE COOKIES BEFORE ACCESSING THE SITES, WITH THE UNDERSTANDING THAT CERTAIN FEATURES OF THE SITES MAY NOT FUNCTION PROPERLY WITHOUT THE AID OF COOKIES. SITES’ USERS WHO REFUSE COOKIES ASSUME ALL RESPONSIBILITY FOR ANY RESULTING LOSS OF FUNCTIONALITY.`,
        ],
    },
    {
        heading: "do we share your personal information?",
        content: [
            {
                heading: "general provisions",
                content: `eazytaxes.com prohibits contractors, affiliates, vendors and suppliers from disclosing any personal information received from eazytaxes.com , other than in accordance with this Privacy Notice. These third parties may include advertisers, providers of games, utilities, widgets and a variety of other third-party applications accessible through the Sites. eazytaxes.com neither owns nor controls the third-party websites and applications accessible through the Sites. Thus, this Privacy Notice does not apply to personal information provided to or gathered by the third parties that operate them. Before visiting a third party, or using a third-party application, whether by means of a link on the Sites, directly through the Sites or otherwise, and before providing any personal information to any such third party, users should inform themselves of the privacy policies and practices (if any) of the third party responsible for those Sites or applications, and should take those steps necessary to, in those users’ discretion, protect their privacy.`,
            },
            {
                heading: "analytics",
                content: `We may use third-party vendors, including Google, who use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize and serve ads based on your past activity on the Sites, including Google Analytics for Display Advertising. The personal information collected may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. If you do not want any personal information to be collected and used by Google Analytics, you can install an opt-out in your web browser (https://tools.google.com/dlpage/gaoptout/) and/or opt out from Google Analytics for Display Advertising or the Google Display Network by using Google’s Ads Settings (www.google.com/settings/ads).`,
            },
            {
                heading: "social media",
                content: `We may use hyperlinks on the Sites which will redirect you to a social network if you click on the respective link. However, when you click on a social plug-in, such as Facebook’s "Like" button or Twitter’s “tweet” button that particular social network’s plugin will be activated and your browser will directly connect to that provider’s servers. If you do not use these buttons none of your data will be sent to the respective social network’s plugin providers. To illustrate this further, imagine the scenario where you click on the Facebook’s "Like" button on the Sites. Facebook will receive your IP address, the browser version and screen resolution, and the operating system of the device you have used to access the Sites. Settings regarding privacy protection can be found on the websites of these social networks and are not within our control.`,
            },
        ],
    },
    {
        heading: "security",
        content: [
            `We take the security of your personal information seriously and use reasonable electronic, personnel and physical measures to protect it from loss, theft, alteration or misuse. However, please be advised that even the best security measures cannot fully eliminate all risks. We cannot guarantee that only authorized persons will view your personal information. We are not responsible for third-party circumvention of any privacy settings or security measures.`,
            `We are dedicated to protect all personal information on the Sites as is necessary. However, you are responsible for maintaining the confidentiality of your personal information by keeping your password and computer/mobile device access confidential. If you have an account with eazytaxes.com and believe someone has gained unauthorized access to it or your account please change your password as soon as possible. If you lose control of your account, you should notify us immediately.`,
        ],
    },
    {
        heading: "privacy rights - eu users",
        content: [
            `If you reside in the European Union (EU), United Kingdom (UK), Switzerland, Norway, Lichtenstein, or Iceland, you have certain data protection rights under your local laws.`,
            `These rights may include:`,
            <ul>
                <li>
                    The right to request access and obtain a copy of your
                    personal information
                </li>
                <li>
                    The right to request edits or erasure of your personal
                    information
                </li>
                <li>
                    The right to limit the processing of your personal
                    information
                </li>
                <li>Data portability right (if applicable)</li>
                <li>
                    The right to object to the processing of your personal
                    information
                </li>
            </ul>,
            `If eazytaxes.com relies on your consent to process your personal information, you have the right to withdraw your consent at any time. However, please note that this will not alter the validity of the processing before its withdrawal.`,
            `If you wish to exercise any of the mentioned rights please contact us by email at hello@eazytaxes.com.com, or by referring to the contact details at the bottom of this Privacy Notice. eazytaxes.com has 30 days to respond to your request.`,
            `If you are not satisfied with eazytaxes.com 's handling of your privacy concerns please note that you have the right to complain to your local data protection supervisory authority.`,
            `List of EU supervisory authorities: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.`,
            `Switzerland supervisory authority: https://www.edoeb.admin.ch/edoeb/en/home.html.`,
            `If you have any questions or comments about your privacy rights, you may email us at hello@eazytaxes.com.com.`,
        ],
    },
    {
        heading: "privacy rights - california users",
        content: [
            `Do Not Sell My Personal Information Notice - California Consumer Privacy Act (CCPA)`,
            `eazytaxes.com has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. eazytaxes.com will not sell personal information in the future belonging to Sites' visitors, users and other consumers.`,
            `If you are under 18 years of age`,
            `If you have registered account with eazytaxes.com , you have the right to request the removal of unwanted personal information that you publicly post on our Sites. To request the removal of such information, please contact us using the contact information provided below. Make sure to include your account's email address and a statement that you reside in California.`,
            `"Shine the Light Law"`,
            `California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, personal information about the personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year. If applicable, this personal information would include a list of the categories of the personal information that was shared and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to the address listed below.`,
        ],
    },
    {
        heading: "do-not-track-notice",
        content: [
            `Most web browsers and some mobile operating systems include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. Because there is not yet a common understanding of how to interpret the DNT signal, the Sites currently do not respond to DNT browser signals or mechanisms.`,
        ],
    },
    {
        heading: "governing law",
        content: [
            `Disputes over privacy issues contained in this Privacy Notice will be governed by the law of the State of California. You also agree to abide by any limitation on damages contained in our Terms of Use, or other agreement that we have with you.`,
        ],
    },
    {
        heading: "privacy notice changes",
        content: [
            `eazytaxes.com may, in its sole discretion, change this Privacy Notice from time to time. Any and all changes to eazytaxes.com ’s Privacy Notice will be reflected on this page and the date of the new version will be stated at the top of this Privacy Notice. Unless stated otherwise, our current Privacy Notice applies to all personal information that we have about you and your account. Users should regularly check this page for any changes to this Privacy Notice. eazytaxes.com will always post new versions of the Privacy Notice on the Sites. However, eazytaxes.com may, as determined in its discretion, decide to notify users of changes made to this Privacy Notice via email or otherwise. Accordingly, it is important that users always maintain and update their contact personal information.`,
        ],
    },
    {
        heading: "contact",
        content: [
            `If you have any questions regarding our Privacy Notice, please contact us at:`,
            <>
                eazytaxes.com
                <br />
                8278 De Palma St
                <br />
                Downey, CA 90241
                <br />
                Email: hello@eazytaxes.com.com
                <br />
                Phone: __________
                <br />
            </>,
        ],
    },
];

export default privacyPolicyPoints;
