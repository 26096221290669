import React from "react";
import {
    Buttons,
    Container,
    Desc,
    Heading,
    SubDesc,
    Layout,
    Background,
    LayoutLeft,
    LayoutRight,
} from "./styles";
import Button from "Components/Global/Button/Button";

const greaterThan = (item, value, greater, smaller) => {
    if (item >= value) {
        return greater;
    } else {
        return smaller;
    }
};

const lessThan = (item, value, greater, smaller) => {
    if (item < value) {
        return greater;
    } else {
        return smaller;
    }
};

const Hero = ({ setIsCalendlyOpen }) => {
    const dash_bg = `${process.env.PUBLIC_URL}/assets/dashboard/img2.png`;
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

    window.addEventListener("resize", () => {
        setInnerWidth(window.innerWidth);
    });
    const listItems = [{
        text: "Accurate Compliance",
        space: lessThan(innerWidth, 1200, true, false),
        pipe: greaterThan(innerWidth, 481, true, false),
    },
    {
        text: "Transparent Pricing",
        prespace: true,
        space: lessThan(innerWidth, 1200, true, false),
        pipe: greaterThan(innerWidth, 481, true, false),
    },
    {
        text: "Maximum Refund",
        space: true,
        prespace: true,
        pipe: false,
    }
    ]
    return (
        <Container src={dash_bg}>
            <Layout>
                <LayoutLeft>
                    <Heading>
                        <span> No. 1 </span> Choice for <span> US Tax </span>filing!
                    </Heading>
                    <Desc>
                        {listItems.map((item, index) => (
                            <div key={index}>
                                {item.prespace && <span>&nbsp;</span>}
                                {item.text}
                                {item.space && <span>&nbsp;</span>}
                                {item.pipe && <span> &nbsp;|&nbsp; </span>}
                            </div>
                        ))}
                    </Desc>
                    <SubDesc>
                        We take care of your tax, so you can sit back and relax!
                    </SubDesc>

                    <Buttons>
                        <Button
                            to="/get-my-taxes-filed"
                            style={{ border: "0.1px solid #55bb53", width: "100%" }}
                            padding={innerWidth >= 481 ? "1.7rem 3.5rem" : "1rem 2rem"}
                        >
                            Begin my tax filing process
                        </Button>
                        <Button
                            style={{ border: "0.1px solid #55bb53", width: lessThan(innerWidth, 482, "80%", "auto") }}
                            padding={() => greaterThan(innerWidth, 481, "1.7rem 3.5rem", "1rem 2rem")}
                            bg="transparent"
                            color="#55bb53"
                            hoverBg="#55bb53"
                            hoverColor="#fff"
                            onClick={() => setIsCalendlyOpen(true)}
                        >
                            Schedule a call today
                        </Button>
                    </Buttons>
                </LayoutLeft>
                <LayoutRight>
                    <Background src={dash_bg}> </Background>
                </LayoutRight>
            </Layout>
        </Container>
    );
};

export default Hero;
