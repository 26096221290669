import axios from "axios";
import { refreshAccessToken } from "./users.api";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/filetaxes`,
    headers: {
        "Content-Type": "application/json",
    },
};

const fileTaxes = axios.create(config);
const fileTaxesAuthenticated = axios.create(config);

fileTaxesAuthenticated.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            const accessToken = JSON.parse(authToken).accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

fileTaxesAuthenticated.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            !error.config.retry
        ) {
            error.config.retry = true;
            const accessToken = await refreshAccessToken();
            localStorage.setItem(
                "authToken",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("authToken")),
                    accessToken,
                })
            );
            return fileTaxesAuthenticated(error.config);
        }
        return Promise.reject(error);
    }
);

export const fileYourTaxes = async (data) => {
    return await fileTaxesAuthenticated.post("/", data);
};

export const fileTaxAdmin = async (data) =>
    await fileTaxesAuthenticated.post("/file", data);

export const getFiledTaxDetails = async (id) =>
    await fileTaxesAuthenticated.get(`/${id}`);

export const getFiledTaxes = async (queryParams) =>
    await fileTaxesAuthenticated.get("/", {
        params: queryParams,
    });

export const getTotalTaxesFiled = async (queryParams) =>
    await fileTaxesAuthenticated.get("/total", {
        params: queryParams,
    });

export const taxFilingStatus = async () => {
    return await fileTaxesAuthenticated.get("/status");
};

export const getAllQuestions = async (requestId) => {
    return await fileTaxesAuthenticated.post("/questions", {
        id: requestId,
    });
};

export const getAllDocuments = async (requestId) => {
    return await fileTaxesAuthenticated.post("/documents", {
        id: requestId,
    });
};

export const createTaxFilingPaymentSession = async (taxFilingRequestId) => {
    return await fileTaxesAuthenticated.post("/create-payment-session", {
        id: taxFilingRequestId,
    });
};

export const changePostPaymentFileTaxesDetails = async (newDetails) => {
    return await fileTaxesAuthenticated.put(
        "/post-payment-details",
        newDetails
    );
};

export const getUploadPostPaymentDocumentPresignedUrl = async (requestId) => {
    return await fileTaxesAuthenticated.post(
        "/post-payment-document-upload-url",
        {
            requestId,
        }
    );
};

export const postPaymentDocumentsUpload = async (data) => {
    return await fileTaxesAuthenticated.post(
        "/post-payment-documents-upload",
        data
    );
};

export const sendTaxFilerContactInfo = async (data) =>
    await fileTaxes.post("/contactinfo", data);
