import styled from "styled-components";

export const Container = styled.div``;

export const TabsGroup = styled.div`
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
`;

export const Tab = styled.div`
    flex: 1;
    cursor: pointer;
    text-align: center;
    font-size: 2rem;
    padding-bottom: 1.5rem;
    font-weight: bold;
    border-bottom: 4px solid
        ${({ isActive }) => (isActive ? "#55bb53" : "transparent")};
`;
