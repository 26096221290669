import React, { createContext, useContext, useState, useEffect } from "react";
import { taxFilingStatus } from "../Api/filetaxes.api";
import { LoadingContext } from "./LoadingContext";
import { AuthContext } from "./AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

export const TaxFilerStatusContext = createContext(null);

const TaxFilerStatusContextProvider = ({ children }) => {
    const [loading, startApiCall, finishApiCall] = useContext(LoadingContext);
    const [statusOfTaxFiling, setStatusOfTaxFiling] = useState(null);
    console.log({ statusOfTaxFiling })
    const [user] = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const fetchTaxFilingStatus = async () => {
        startApiCall();
        try {
            const res = await taxFilingStatus();
            setStatusOfTaxFiling(res.data);
        } catch (err) {
        } finally {
            finishApiCall();
        }
    };
    // useEffect(() => {
    //     fetchTaxFilingStatus();
    // }, [user]);
    useEffect(() => {
        if (!loading) {
            if (
                pathname === "/dashboard" ||
                (pathname === "/dashboard/" && user.user_type !== "admin")
            ) { user ? navigate("/dashboard") : navigate("/get-my-taxes-filed") };
            if (
                pathname === "/get-my-taxes-filed" ||
                pathname === "/get-my-taxes-filed/"
            ) { user ? navigate("/dashboard") : navigate("/get-my-taxes-filed") };
        }
    }, [loading, navigate, statusOfTaxFiling, pathname]);
    return (
        <TaxFilerStatusContext.Provider
            value={[statusOfTaxFiling, fetchTaxFilingStatus]}
        >
            {children}
        </TaxFilerStatusContext.Provider>
    );
};

export default TaxFilerStatusContextProvider;
