import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  LoginContainer,
  Title,
  Image,
  TitleImage,
} from "./styles";
import "react-phone-number-input/style.css";

import "@lottiefiles/lottie-player";

import Login from "../Accounts/Login";
import Signup from "../Accounts/Signup";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../Context/LoadingContext.js";
import { AuthContext } from "../../Context/AuthContext.js";
import { TaxFilerStatusContext } from "../../Context/TaxFilerStatusContext";

const GetMyTaxesFiled = () => {
  const [userState, setUserState] = useState("signup");
  const [user] = useContext(AuthContext);
  const [, startApiCall, finishApiCall] = useContext(LoadingContext);
  const [, fetchTaxFilingStatus] = useContext(TaxFilerStatusContext);
  const navigate = useNavigate();



  useEffect(() => {
    document.title = "Get My Taxes Filed • Eazytaxes";
  }, []);

  return (
    <Container>
      <TitleImage>
        <Title> <span>524</span> people are filing their taxes with <span>eazytaxes.com</span></Title>
        <Image  src={`${process.env.PUBLIC_URL}/assets/dashboard/group.jpg`} />
      </TitleImage>
      <LoginContainer>
        {userState === "login" ? <Login /> : <Signup />}
      </LoginContainer>
    </Container>
  );
};

export default GetMyTaxesFiled;
