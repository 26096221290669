import React from "react";
import ShownWayTo from "../ShownWayTo/ShownWayTo";
import { Container, Content, Heading } from "./styles";

const ShowedThemWay = () => {
  return (
    <Container>
      <Heading>
        Real People, <span>Real Reviews</span>
      </Heading>
      <Content>
        <ShownWayTo />
      </Content>
    </Container>
  );
};

export default ShowedThemWay;
