import styled from "styled-components";

export const Container = styled.div`
    max-width: 120rem;
    margin: 10rem auto;
    padding: 0 2.5rem;
    text-align: center;
`;

export const Heading = styled.div`
    color: #0b2a41;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
    & span {
        background: linear-gradient(
            45deg,
            #55bb53 0%,
            #0d71ba 80.42%,
            #0d71ba 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width: 700px) {
        font-size: 3rem;
    }
`;

export const Content = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (max-width: 400px) {
        gap: 0;
    }
`;

export const Card = styled.div`
    max-width: 37rem;
    min-width: 30rem;
    flex: 1;
    padding: 2rem 3.5rem 0rem;
    /* transition: background 0.3s linear;
    &:hover {
        background: #0b2a41;
    } */
    @media screen and (max-width: 400px) {
        padding: 2rem;
    }
`;

export const CardIcon = styled.div`
    width: 7rem;
    height: 7rem;
    margin: auto;
    border-radius: 0.3rem;
    background: url(${({ src }) => src});
    background-size: 7rem;
    background-repeat: no-repeat;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* font-size: 4rem;
    color: #55bb53; */
    /* transition: background 0.3s linear;
    ${Card}:hover & {
        background: #12344d;
    } */
`;

export const CardHeading = styled.div`
    padding: 2.5rem 0 1.5rem 0;
    color: #0b2a41;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
    /* transition: color 0.3s linear; */
    /* ${Card}:hover & {
        color: #fff;
    } */
`;

export const CardDesc = styled.div`
    line-height: 1.875;
    font-weight: 400;
    color: #60626d;
    /* transition: color 0.3s linear;
    ${Card}:hover & {
        color: #afc1ce;
    } */
`;
