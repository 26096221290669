import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  CloseHamMenu,
  Container,
  Content,
  HamMenuLink,
  HamMenuLinks,
  HamMenuLinksSeparator,
  LoginSignup,
} from "./styles";
import { BsArrowRight } from "react-icons/bs";
import Button from "../Button/Button";
import { AuthContext } from "Context/AuthContext";
import { logout } from "Api/users.api";
import { successToast } from "toasts";
import { LoadingContext } from "Context/LoadingContext";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

const hamMenuLinks = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "About Us",
    to: "/about-us",
  },
  {
    name: "Contact Us",
    to: "/contact-us",
  },
  {
    to: "/services",
    name: "Services",
  },
  {
    name: "Pricing",
    to: "/pricing",
  },
  {
    name: "File My Taxes Now",
    to: "/get-my-taxes-filed",
  },
];

const items = [
  {
    label: "Services",
    children: [
      {
        label: "Salaried Professional",
        key: "1",
        link: "/services/salaried-professional",
        // type: "group",
      },
      {
        label: "Ebay, Shopify, Amazon Sellers",
        key: "2",
        link: "/services/ebay-shopify-amazon-sellers",
        // type: "group",
      },
      {
        label: "Small Business Owners",
        key: "3",
        link: "/services/small-business-owners",
        // type: "group",
      },
      {
        label: "Students",
        key: "4",
        link: "/services/students",
        // type: "group",
      },
    ],
  },
];
const HamMenu = ({ closeHamMenu }) => {
  // const [isHamMenuVisible, setIsHamMenuVisible] = useContext();
  // useEffect(() => {
  //     if (isHamMenuVisible) document.body.style.overflow = "hidden";
  //     else document.body.style.overflow = "auto";
  //     return () => (document.body.style.overflow = "auto");
  // }, [isHamMenuVisible]);
  const navigate = useNavigate();
  const appendOnClickinItems = (items) => {
    items.forEach((item) => {
      if (item.children) {
        appendOnClickinItems(item.children);
      } else {
        item.onClick = onClick;
      }
    });
  };
  const onClick = ({ item }) => {
    console.log("click ", item?.props?.link);
    navigate(item?.props?.link);
    closeHamMenu();
  };
  appendOnClickinItems(items);
  const [, startApiCall, finishApiCall] = useContext(LoadingContext);
  const [user, refetchUserDetails] = useContext(AuthContext);
  const logoutHandler = async () => {
    startApiCall();
    await logout();
    localStorage.removeItem("authToken");
    refetchUserDetails();
    successToast("Logged out successfully!");
    finishApiCall();
  };
  return (
    <Container onClick={closeHamMenu}>
      <Content onClick={(e) => e.stopPropagation()}>
        <CloseHamMenu>
          <BsArrowRight onClick={closeHamMenu} />
        </CloseHamMenu>
        <HamMenuLinks>
          {hamMenuLinks.map(({ name, to }, index) => {
            if (name === "Services") {
              return (
                <>
                  <Menu
                    style={{ margin: "0%", backgroundColor: "transparent" }}
                    onSelect={onClick}
                    mode="inline"
                    items={items}
                    label={name}
                  />
                  <HamMenuLinksSeparator />
                </>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <HamMenuLink onClick={closeHamMenu}>
                    <NavLink to={to}>{name}</NavLink>
                  </HamMenuLink>
                  {hamMenuLinks.length - 1 !== index && (
                    <HamMenuLinksSeparator />
                  )}
                </React.Fragment>
              );
            }
          })}
        </HamMenuLinks>
        <LoginSignup>
          {user ? (
            <>
              <Button to="/dashboard/" hoverBg="#f5f5f5" hoverColor="#0b2a41">
                Go To Dashboard
              </Button>
              <Button
                onClick={logoutHandler}
                hoverBg="#f5f5f5"
                hoverColor="#0b2a41"
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button
                to="/accounts/login"
                hoverBg="#f5f5f5"
                hoverColor="#0b2a41"
              >
                Login
              </Button>
              <Button
                to="/accounts/signup"
                hoverBg="#f5f5f5"
                hoverColor="#0b2a41"
              >
                Signup
              </Button>
            </>
          )}
        </LoginSignup>
      </Content>
    </Container>
  );
};

export default HamMenu;
