import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    max-width: 45rem;
    margin: auto;
`;

export const Heading = styled.div`
    font-size: 4rem;
    font-weight: bold;
    margin: 2.5rem 0;
`;

export const Desc = styled.div`
    font-size: 2rem;
    margin-bottom: 2.5rem;
`;
