import axios from "axios";
import { refreshAccessToken } from "./users.api";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/documents`,
    headers: {
        "Content-Type": "application/json",
    },
};

const documents = axios.create(config);
const authenticatedDocuments = axios.create(config);

authenticatedDocuments.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            const accessToken = JSON.parse(authToken).accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

authenticatedDocuments.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            !error.config.retry
        ) {
            error.config.retry = true;
            const accessToken = await refreshAccessToken();
            localStorage.setItem(
                "authToken",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("authToken")),
                    accessToken,
                })
            );
            return authenticatedDocuments(error.config);
        }
        return Promise.reject(error);
    }
);

const getAllDocuments = async () => await authenticatedDocuments.get("/");

const editDocument = async ({ id, ...data }) =>
    await authenticatedDocuments.patch(`/${id}`, data);

const createNewDocument = async (data) =>
    await authenticatedDocuments.post("/", data);

const updateDocumentStatus = async ({ id, active }) =>
    await authenticatedDocuments.patch(`/${id}/status`, { active });

const updateDocumentOptionalStatus = async ({ id, optional }) =>
    await authenticatedDocuments.patch(`/${id}/optional`, { optional });

export {
    getAllDocuments,
    editDocument,
    createNewDocument,
    updateDocumentStatus,
    updateDocumentOptionalStatus,
};
