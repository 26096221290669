import React from "react";
import Marquee from "react-fast-marquee";
import {
    Companies,
    Company,
    Container,
    Content,
    Heading,
} from "./styles";

const OurClientsWorkAt = ({ hideReadWhatTheyHaveToSay }) => {
    const companiesrow1 = [
        "adobe",
        "amazon",
        "facebook",
        "netflix",
        "nutanix",
        "adobe",
        "walmart",
        "accenture",
        "ibm",
        "fedex",
    ];
    const companiesrow2 = [
        "starbucks",
        "deloitte",
        "dhl",
        "cognizant",
        "pepsico",
        "jpmorgan",
        "at&t",
        "cvshealth",
        "bankofamerica",
        "unitedparcelservice",
    ];
    const baseURL = `${process.env.PUBLIC_URL}/assets/home/brand-logos/`;
    return (
        <Container>
            <Heading>
                Our <span>clients work</span> at
            </Heading>
            <Content>
                <Marquee speed={40}>
                    <Companies>
                        {companiesrow1.map((company, index) => (
                            <Company
                                key={index}
                                src={`${baseURL}${company}.png`}
                            />
                        ))}
                    </Companies>
                </Marquee>
                <Marquee direction="right" speed={40}>
                    <Companies>
                        {companiesrow2.map((company, index) => (
                            <Company
                                key={index}
                                src={`${baseURL}${company}.png`}
                            />
                        ))}
                    </Companies>
                </Marquee>
            </Content>
            {/* {!hideReadWhatTheyHaveToSay && (
                <SuccessStories>
                    <NavLink to="/testimonials">
                        <span>Read what they have to say about us</span>
                    </NavLink>
                </SuccessStories>
            )} */}
        </Container>
    );
};

export default OurClientsWorkAt;
