import styled from "styled-components";

export const Container = styled.textarea`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.2rem 2.4rem;
    border: 0.1rem solid #dbdbdb;
    border-radius: 0.3rem;
    transition: border 0.3s;
    font-size: 1.6rem;
    &:focus {
        border-color: ${({ isValid }) => (isValid ? "#55bb53" : "red")};
    }
`;

export const Error = styled.div`
    color: red;
    margin-top: 0.5rem;
`;
