import { useCallback, useEffect, useRef, useState } from "react";

const useFileDragAndDrop = ({ onChange = () => {}, ref, allowedTypes }) => {
    const inputRef = useRef();
    const [fileDropError, setFileDropError] = useState(null);
    const [fileBeingDragged, setFileBeingDragged] = useState(false);
    const fileHandler = useCallback(
        (file) => {
            setFileBeingDragged(false);
            setFileDropError(null);
            onChange(file);

            //checking file type
            if (
                Array.isArray(allowedTypes) &&
                allowedTypes.length &&
                !allowedTypes.includes(file.type)
            )
                setFileDropError(true);
            else setFileDropError(false);
        },
        [allowedTypes, onChange]
    );
    const browse = () => {
        inputRef.current?.click();
    };
    useEffect(() => {
        inputRef.current = document.createElement("input");
        inputRef.current.type = "file";
        inputRef.current.addEventListener("change", (e) => {
            const input = e.target;
            if (!input.files?.length) return;
            fileHandler(input.files[0]);
        });
        if (ref.current) {
            ref.current.addEventListener("dragover", (e) => {
                e.preventDefault();
                setFileBeingDragged(true);
            });
            ["dragleave", "dragend"].forEach((event) =>
                ref.current?.addEventListener(event, () => {
                    setFileBeingDragged(false);
                })
            );
            ref.current.addEventListener("drop", (e) => {
                e.preventDefault();
                const file = e.dataTransfer?.files[0];
                if (file) fileHandler(file);
            });
        }
    }, [ref, fileHandler]);
    return { fileBeingDragged, fileDropError, browse };
};

export default useFileDragAndDrop;
