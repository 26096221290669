import styled from "styled-components";

export const Container = styled.div`
    max-width: 120rem;
    margin: 4rem auto;
`;

export const Content = styled.div`
    margin-top: 6rem;
`;

export const ForwardBackwardButtons = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 45rem;
    margin: 4rem auto 0;
`;
