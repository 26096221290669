import styled from "styled-components";

export const Container = styled.div`
    padding: 0 2.5rem;
    margin: 10rem 0;
    @media screen and (max-width: 760px) {
        margin: 5rem 0;
    }
`;

export const Content = styled.div`
    max-width: 120rem;
    margin: auto;
    padding: 0 4.8rem;
    display: flex;
    align-items: center;
    box-shadow: 0px 30px 60px 0px rgb(164 172 179 / 20%);
    @media screen and (max-width: 900px) {
        flex-direction: column;
        gap: 2.5rem;
        padding: 4.8rem;
        text-align: center;
    }
`;

export const HeadingGetStarted = styled.div`
    width: 50%;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;

export const Heading = styled.div`
    line-height: 120%;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 3.6rem;
    color: #0b2a41;
    @media screen and (max-width: 1000px) {
        font-size: 3.5rem;
        margin-bottom: 3rem;
    }
    @media screen and (max-width: 900px) {
        margin-bottom: 2rem;
    }
    @media screen and (max-width: 600px) {
        font-size: 3rem;
    }
`;

export const Icon = styled.img`
    width: 50%;
    @media screen and (max-width: 900px) {
        width: 60%;
    }
    @media screen and (max-width: 700px) {
        width: 80%;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`;
