import React, { useEffect, useState } from "react";

import { Container, Heading, Desc, Buttons } from "./styles";
import Reviews from "../../../Components/Home/Reviews/Reviews";
import Button from "../../../Components/Global/Button/Button.jsx";

const FBASellers = () => {
  useEffect(() => {
    document.title = "Services • Eazytaxes";
  }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isCalendlyOpen ? "hidden" : "auto";
  }, [isCalendlyOpen]);

  return (
    <Container>
      <Heading>
        Comprehensive <span>Tax Compliance</span> for Global Entrepreneurs
      </Heading>
      <Desc>
        We understand that as an e-commerce seller, it can be difficult to keep
        track of all your tax obligations and ensure proper compliance. That's
        where we come in.
        <br /> <br />
        Our team of experienced tax professionals will help you navigate the
        complex tax landscape of e-commerce and ensure that you are in
        compliance with all federal, state, and local tax laws. We offer a range
        of services, including tax preparation, tax planning, and tax
        representation, to help you stay on top of your tax obligations and
        minimize your risk of an audit or other tax issues. Our online platform
        makes it easy for you to access our services from anywhere, at any time.
        Simply upload your tax documents and let us handle the rest. We'll
        review your documents, calculate your tax liability, and prepare all
        necessary tax returns and forms. We'll also provide you with
        personalized advice and guidance to help you minimize your tax burden
        and maximize your profits.
        <br /> <br />
        In addition to our tax preparation services, we also offer compliance
        assistance to help you stay on top of your tax obligations. We'll help
        you track your sales tax obligations, register for the appropriate
        business licenses, and make sure you're in compliance with all relevant
        tax laws. By choosing our online tax preparation service, you can feel
        confident that your taxes are being handled by professionals who are
        dedicated to helping you succeed. Contact us today to learn more about
        how we can help you ensure proper compliance and take the stress out of
        tax season.
      </Desc>
      <Buttons>
        <Button
          to="/get-my-taxes-filed"
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
        >
          Begin my tax filing process
        </Button>
        <Button
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
          bg="transparent"
          color="#55bb53"
          hoverBg="#55bb53"
          hoverColor="#fff"
          onClick={() => setIsCalendlyOpen(true)}
        >
          get free consultation
        </Button>
      </Buttons>
      <Reviews />
    </Container>
  );
};

export default FBASellers;
