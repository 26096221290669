import styled from "styled-components";

export const Container = styled.div`
  padding: 7rem 3rem;
  margin-bottom: 8rem;
  text-align: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 768px) {
    background: none;
    padding: 3rem 3rem;
    margin-bottom: 8rem;

  }
  @media screen and (max-width: 400px) {
    background: none;
    padding: 1rem 3rem;
    margin-bottom: 8rem;
  }
`;

export const Background = styled.div`
  max-width: 100%;
  height: 100%;
  background: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: cover;
  @media screen and (max-width: 1025px) {
    background: none;
  }
  @media screen and (max-width: 1200px) {
    max-width: 90%;
    height: 90%;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LayoutLeft = styled.div`
  flex: 3;
  padding-top: 8rem;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const LayoutLeftList = styled.div`
  margin: 2rem;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 5rem;
`;

export const LayoutRight = styled.div`
  flex: 2.5;
  @media screen and (max-width: 1025px) {
    flex: none;
  }
`;

export const Heading = styled.div`
  font-size: 5rem;
  font-weight: 700;
  color: #1b1d21;
  font-family: "Poppins", sans-serif;
  max-width: 80rem;
  margin: auto;
  line-height: 1.2;
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1200px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 1025px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
    margin-top: 6rem;
  }
  @media screen and (max-width: 670px) {
    font-size: 2.5rem;
    margin-top: 6rem;
  }
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 110rem;
  font-size: 2.2rem;
  font-weight: 600;
  margin: 3rem auto 3rem;
  line-height: 30px;
  @media screen and (max-width: 1300px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 1110px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    font-size: 2rem;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    font-size: 1.8rem;
    & div {
      // border: 1px solid #000;
      width: 70%;
      margin: 0.3rem auto;
      border-radius: 0.5rem;
      box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 8%);
      font-size: 1.8rem;
      background: rgb(85,187,83);
      background: linear-gradient(90deg, rgba(85,187,83,1) 0%, rgba(13,113,186,1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }
  }
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin: 2rem;
  @media screen and (max-width: 481px) {
    font-size: 0.3rem;
    line-height: 20px;
    margin: 3rem auto;
    }
`;

export const SubDesc = styled.div`
  max-width: 80rem;
  font-size: 2rem;
  font-weight: 500;
  margin: 0.5rem auto 4rem;
  @media screen and (max-width: 670px) {
    font-size: 1.6rem;
    line-height: 20px;
    margin: 2rem auto;
  }
  @media screen and (max-width: 400px) {
    font-size: 1.6rem;
    line-height: 20px;
    margin: 2rem auto;
    }
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
