import React, { useEffect, useState } from "react";
import { PopupModal } from "react-calendly";
import DataSecurity from "Components/Home/DataSecurity/DataSecurity";
import FAQs from "Components/Home/FAQs/FAQs";
import Features from "Components/Home/Features/Features";
import Hero from "Components/Home/Hero/Hero";
import HowEazyTaxesDoIt from "Components/Home/HowEazyTaxesDoIt/HowEazyTaxesDoIt";
import NumbersDontLie from "Components/Home/NumbersDontLie/NumbersDontLie";
import ReadyToGetStarted from "Components/Home/ReadyToGetStarted/ReadyToGetStarted";
import Reviews from "Components/Home/Reviews/Reviews";
import ServicesForYou from "Components/Home/ServicesForYou/ServicesForYou";
import OurClientsWorkAt from "Components/Home/OurClientsWorkAt/OurClientsWorkAt";
import { Container } from "./styles";
import  {default as Pricing} from "Components/Pricing/Pricing";

const Home = () => {
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
    useEffect(() => {
        document.title = "Home • Eazytaxes";
    }, []);
    useEffect(() => {
        document.body.style.overflow = isCalendlyOpen ? "hidden" : "auto";
    }, [isCalendlyOpen]);
    return (
        <Container>
            <PopupModal
                url="https://calendly.com/eazytaxes/30min"
                onModalClose={() => setIsCalendlyOpen(false)}
                open={isCalendlyOpen}
                rootElement={document.getElementById("root")}
            />
            <Hero setIsCalendlyOpen={setIsCalendlyOpen} />
            <Pricing />
            <Features />
            <OurClientsWorkAt />
            <Reviews />
            <DataSecurity />
            <NumbersDontLie />
            <ServicesForYou />
            <HowEazyTaxesDoIt />
            <ReadyToGetStarted />
            <FAQs />
            {/* <ChatBot url="https://storage.googleapis.com/landbot.online/v3/H-1459341-DSUMBW1CVYI98FTQ/index.json" /> */}
        </Container>
    );
};

export default Home;
