import React from "react";
import { FaHome } from "react-icons/fa";
import { TbReportMoney } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Link, LinkIcon, Links, LinkText, Logo } from "./styles";
import { RiQuestionnaireLine } from "react-icons/ri";
import { IoDocumentTextSharp } from "react-icons/io5";

const SideBar = () => {
    const { pathname } = useLocation();
    const links = [
        {
            icon: <FaHome />,
            text: "Home",
            to: "/dashboard/",
        },
        {
            icon: <RiQuestionnaireLine />,
            text: "Questions",
            to: "/dashboard/questions/",
        },
        {
            icon: <IoDocumentTextSharp />,
            text: "Documents",
            to: "/dashboard/documents/",
        },
    ];
    return (
        <Container>
            <Logo
                alt="eazytaxes.com"
                src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
            />
            <Links>
                {links.map(({ to, text, icon }, index) => (
                    <NavLink key={index} to={to}>
                        <Link
                            isActive={
                                (pathname.endsWith("/")
                                    ? pathname
                                    : `${pathname}/`) === to
                            }
                        >
                            <LinkIcon>{icon}</LinkIcon>
                            <LinkText>{text}</LinkText>
                        </Link>
                    </NavLink>
                ))}
            </Links>
        </Container>
    );
};

export default SideBar;
