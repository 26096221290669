import styled from "styled-components";

export const Container = styled.div`
    background: #f5f5f5;
    border-radius: 1.6rem;
    display: flex;
    padding: 2.4rem;
    width: 50rem;
    gap: 2rem;
    @media screen and (max-width: 700px) {
        width: 40rem;
        flex-direction: column;
        text-align: center;
    }
    @media screen and (max-width: 500px) {
        width: 30rem;
        flex-direction: column;
        text-align: center;
    }
`;

export const UserPic = styled.div`
    width: 9rem;
    height: 9rem;
    background: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;
    @media screen and (max-width: 700px) {
        margin: 0 auto;
    }
`;

export const Content = styled.div`
    /* color: #c5d1da; */
    color: #000;
    font-size: 1.7rem;
    line-height: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const ReviewContent = styled.div`
    flex-grow: 1;
    font-size: 1.4rem;
    line-height: 25px;


`;

export const UserName = styled.div`
    /* font-size: 2.2rem; */
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    /* color: #fff; */
    color: #000;
`;
