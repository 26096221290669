import React from "react";
import { Container, Desc, Heading } from "./styles";

const Review = ({ statusOfTaxFiling }) => {

    return (
        <Container>
            {false ? (
                <>
                    {/* <lottie-player
                        style={{ width: "60%", margin: "auto" }}
                        autoplay
                        loop
                        mode="normal"
                        src="https://assets2.lottiefiles.com/private_files/lf30_z1sghrbu.json"
                    ></lottie-player>
                    <Heading>Your Taxes Are Filed</Heading>
                    <Desc>
                        We have filed your taxes. You can download the
                        attachements from below button.
                    </Desc>
                    <Button onClick={downloadAttachements}>
                        Download all attachements
                    </Button> */}
                </>
            ) : (
                <>
                    <lottie-player
                        style={{ width: "60%", margin: "auto" }}
                        autoplay
                        loop
                        mode="normal"
                        src="https://assets4.lottiefiles.com/private_files/lf30_0jnbucxz.json"
                    ></lottie-player>
                    <Heading>Filing Your Taxes</Heading>
                    <Desc>
                        Thank you! We are currently preparing your tax return. It takes 24-48 hours normally to process your tax documents.
                    </Desc>
                </>
            )}
        </Container>
    );
};

export default Review;
