import React, { useContext, useEffect, useState } from "react";
import Input from "../../Components/Global/Input/Input";
import {
    Buttons,
    Container,
    Desc,
    Heading,
    InputGroup,
    SuccessErrorDesc,
    SuccessErrorIcon,
} from "./styles";
import { AiOutlineMail } from "react-icons/ai";
import emailValidationSchema from "../../ValidationSchemas/emailValidationSchema";
import Button from "../../Components/Global/Button/Button";
import { sendForgotPasswordMail } from "../../Api/users.api";
import { errorToast, successToast } from "../../toasts.js";
import "@lottiefiles/lottie-player";
import { LoadingContext } from "../../Context/LoadingContext.js";

const ForgotPassword = () => {
    const [registeredEmail, setRegisteredEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    useEffect(() => {
        document.title = "Forgot Password • Eazytaxes";
    }, []);
    const handleSendMail = async () => {
        try {
            startApiCall();
            await sendForgotPasswordMail({ email: registeredEmail });
            if (isEmailSent) {
                successToast(
                    `Email resent successfully to ${registeredEmail}.`
                );
            } else {
                setIsEmailSent(true);
            }
        } catch (err) {
            errorToast(err.response.data.error.message);
        } finally {
            finishApiCall();
        }
    };
    return (
        <Container style={isEmailSent ? { maxWidth: "60%" } : {}}>
            {isEmailSent ? (
                <>
                    <SuccessErrorIcon src="https://cdn-icons-png.flaticon.com/512/2593/2593557.png" />
                    <SuccessErrorDesc>
                        Email sent successfully to {registeredEmail}.{" "}
                        <span onClick={handleSendMail}>Resend</span>
                    </SuccessErrorDesc>
                </>
            ) : (
                <>
                    <Heading>Forgot your password? No worries!</Heading>
                    <Desc>
                        Type email registered to your account to send forgot
                        password link
                    </Desc>
                    <InputGroup>
                        <Input
                            placeholder="Email Registered With Account"
                            icon={<AiOutlineMail />}
                            value={registeredEmail}
                            validationSchema={emailValidationSchema}
                            onValidation={(isValid) => setIsEmailValid(isValid)}
                            onChange={({ target }) =>
                                setRegisteredEmail(target.value)
                            }
                        />
                    </InputGroup>
                    <Buttons>
                        <Button
                            linkStyle={{
                                width: "100%",
                            }}
                            style={{
                                textTransform: "capitalize",
                                width: "100%",
                                fontSize: "1.8rem",
                            }}
                            isDisabled={!isEmailValid}
                            hoverBg="#f5f5f5"
                            hoverColor="#0b2a41"
                            onClick={handleSendMail}
                        >
                            Send Mail
                        </Button>
                    </Buttons>
                </>
            )}
        </Container>
    );
};

export default ForgotPassword;
