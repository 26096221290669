import React, { useEffect, useState } from "react";
import { Container, InputGroup } from "./styles";
import { Table, TableRow, Td, Th } from "../../../globalStyles";
import {
    createNewQuestion,
    editQuestion,
    getAllQuestions,
    updateQuestionOptionalStatus,
    updateQuestionStatus,
} from "../../../Api/questions.api";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { AiFillEdit } from "react-icons/ai";
import Modal from "../../../Components/Global/Modal/Modal";
import Input from "../../../Components/Global/Input/Input";

const TaxFilingQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState({
        id: null,
        question: "",
        desc: "",
    });
    const [showEditNewQuestionModal, setShowEditNewQuestionModal] = useState({
        state: false,
        for: null,
    });
    const getAndSetAllQuestions = () =>
        getAllQuestions().then(({ data }) => setQuestions(data));
    const modalOnSubmit = async () => {
        if (showEditNewQuestionModal.for === "edit")
            await editQuestion(question);
        else await createNewQuestion(question);
        setShowEditNewQuestionModal(false);
        getAndSetAllQuestions();
    };
    useEffect(() => {
        document.title = "Dashboard - Tax Filing Questions • Eazytaxes";
        getAndSetAllQuestions();
    }, []);
    return (
        <Container>
            {showEditNewQuestionModal.state && (
                <Modal
                    heading={
                        showEditNewQuestionModal.for === "edit"
                            ? "Edit Question"
                            : "Create Question"
                    }
                    onClose={() => setShowEditNewQuestionModal(false)}
                    onSubmit={modalOnSubmit}
                    style={{ maxWidth: "60rem", width: "100%" }}
                    buttonProps={{
                        isDisabled:
                            !Boolean(question.question) ||
                            !Boolean(question.desc),
                    }}
                    buttonText={
                        showEditNewQuestionModal.for === "edit"
                            ? "save"
                            : "create"
                    }
                >
                    <InputGroup>
                        <Input
                            placeholder="Question"
                            value={question.question}
                            onChange={({ target }) =>
                                setQuestion({
                                    ...question,
                                    question: target.value,
                                })
                            }
                        />
                        <Input
                            placeholder="Desc"
                            value={question.desc}
                            onChange={({ target }) =>
                                setQuestion({
                                    ...question,
                                    desc: target.value,
                                })
                            }
                        />
                    </InputGroup>
                </Modal>
            )}
            <Table>
                <TableRow>
                    <Th>Id</Th>
                    <Th>Question</Th>
                    <Th>Desc</Th>
                    <Th>Active</Th>
                    <Th>Optional</Th>
                    <Th>Edit</Th>
                </TableRow>
                {questions.map(
                    ({ id, question, desc, is_active, is_optional }) => (
                        <TableRow key={id}>
                            <Td>{id}</Td>
                            <Td>{question}</Td>
                            <Td>{desc}</Td>
                            <Td>
                                <Toggle
                                    defaultChecked={is_active}
                                    onChange={async ({ target }) => {
                                        await updateQuestionStatus({
                                            id,
                                            active: target.checked,
                                        });
                                        getAndSetAllQuestions();
                                    }}
                                />
                            </Td>
                            <Td>
                                <Toggle
                                    defaultChecked={is_optional}
                                    onChange={async ({ target }) => {
                                        await updateQuestionOptionalStatus({
                                            id,
                                            optional: target.checked,
                                        });
                                        getAndSetAllQuestions();
                                    }}
                                />
                            </Td>
                            <Td
                                style={{
                                    cursor: "pointer",
                                    fontSize: "2.5rem",
                                }}
                            >
                                <AiFillEdit
                                    onClick={() => {
                                        setShowEditNewQuestionModal({
                                            state: true,
                                            for: "edit",
                                        });
                                        setQuestion({
                                            id,
                                            question,
                                            desc,
                                        });
                                    }}
                                />
                            </Td>
                        </TableRow>
                    )
                )}
            </Table>
            <button
                onClick={() => {
                    setShowEditNewQuestionModal({
                        state: true,
                        for: "create",
                    });
                    setQuestion({
                        question: "",
                        desc: "",
                    });
                }}
            >
                Add New Question
            </button>
        </Container>
    );
};

export default TaxFilingQuestions;
