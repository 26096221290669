import React, { useEffect } from "react";
import OurClientsWorkAt from "../../Components/Home/OurClientsWorkAt/OurClientsWorkAt";
import Reviews from "../../Components/Home/Reviews/Reviews";
import ShowedThemWay from "../../Components/Testimonials/ShowedThemWay/ShowedThemWay";
import { Container } from "./styles";

const Testimonials = () => {
    useEffect(() => {
        document.title = "Testimonials • Eazytaxes";
    }, []);
    return (
        <Container>
            <ShowedThemWay />
            <OurClientsWorkAt hideReadWhatTheyHaveToSay />
            <Reviews />
        </Container>
    );
};

export default Testimonials;
