import styled from "styled-components";

export const Table = styled.table`
    border: 0.1rem solid #dee2e6;
    & thead tr {
        background: transparent !important;
    }
`;

export const TableRow = styled.tr`
    &:nth-child(odd) {
        background: rgba(85, 187, 83, 0.3);
    }
`;

export const Th = styled.th`
    border: none;
    text-align: left;
    padding: 2rem;
`;

export const Td = styled.td`
    border: none;
    padding: 2rem;
`;
