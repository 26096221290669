import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 5rem;
`;

export const Desc = styled.div`
  line-height: 1.8;
  font-weight: 400;
  font-size: 2rem;
  margin: auto;
  width: 80%;
  padding: 0 1.5rem;
  text-align: justify;
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 2rem 0 0 0;
  text-align: center;
  & span {
    background: linear-gradient(
      45deg,
      #55bb53 0%,
      #0d71ba 80.42%,
      #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin: 5rem 0 0 0;
`;
