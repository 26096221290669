import React, { useContext, useState } from "react";
import Blog from "../../Components/Blogs/Blog/Blog";
import { BlogsList, Container } from "./styles";
import Pagination from "react-responsive-pagination";
import { useEffect } from "react";
import axios from "axios";
import { LoadingContext } from "../../Context/LoadingContext.js";

const Blogs = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [blogs, setBlogs] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const getBlogs = () => {
        startApiCall();
        axios
            .get(
                `https://blogs.eazytaxes.com/ghost/api/content/posts/?key=32fb490f17b744125aa2ec873d&limit=6&page=${currentPage}`
            )
            .then(({ data }) => {
                const { meta, posts } = data;
                setTotalPages(meta.pagination.pages);
                setBlogs(
                    posts.map(
                        ({
                            url,
                            published_at,
                            title,
                            feature_image,
                            meta_description,
                        }) => ({
                            link: url,
                            published: published_at,
                            title,
                            image: feature_image,
                            desc: meta_description,
                        })
                    )
                );
                finishApiCall();
            });
    };
    useEffect(() => {
        getBlogs();
    }, [currentPage]);
    useEffect(() => {
        document.title = "Blogs • Eazytaxes";
    }, []);
    function handlePageChange(page) {
        setCurrentPage(page);
    }
    return (
        <Container>
            <BlogsList>
                {blogs.map((blog, index) => (
                    <Blog key={index} {...blog} />
                ))}
            </BlogsList>
            {totalPages !== 1 && (
                <Pagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={(page) => handlePageChange(page)}
                />
            )}
        </Container>
    );
};

export default Blogs;
