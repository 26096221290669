import React, { useEffect } from "react";
import { Container, FeatureImage } from "./styles";

const DataSecurity = () => {
  useEffect(() => {
    document.title = "Pricing • Eazytaxes";
  }, []);

  const images = [
    `${process.env.PUBLIC_URL}/assets/data/img1.jpg`,
    `${process.env.PUBLIC_URL}/assets/data/img2.jpg`,
    `${process.env.PUBLIC_URL}/assets/data/img3.png`,
    `${process.env.PUBLIC_URL}/assets/data/img4.jpg`,
    `${process.env.PUBLIC_URL}/assets/data/img5.png`,
    `${process.env.PUBLIC_URL}/assets/data/img6.png`,
  ];

  return (
    <React.Fragment>
      <Container>
        {images.map((image) => {
          return <FeatureImage src={image} />;
        })}
      </Container>
    </React.Fragment>
  );
};

export default DataSecurity;
