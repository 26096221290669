import React, { useState, useEffect, useContext } from "react";
import Modal from "../../../../Global/Modal/Modal";
import {
    fileTaxAdmin,
    getFiledTaxDetails,
} from "../../../../../Api/filetaxes.api";
import {
    uploadFileFromPresignedUrl,
    generatePresignedUrls,
} from "../../../../../Api/presignedurl.api";
import { Table, TableRow, Td, Th } from "../../../../../globalStyles";
import Tabs from "../../../../Global/Tabs/Tabs";
import {
    Answer,
    DocumentUpload,
    DocumentUploadName,
    DocumentUploadRemove,
    DocumentUploadZoneRemove,
    DocumentUploads,
    Question,
    QuestionAnswer,
    QuestionAnswers,
    TaxesFiled,
} from "./styles";
import Button from "../../../../Global/Button/Button";
import DocumentUploadZone from "../../../../Global/DocumentUploadZone/DocumentUploadZone";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { successToast } from "../../../../../toasts.js";
import { LoadingContext } from "../../../../../Context/LoadingContext";

const TaxFilingRequestDetails = ({ onClose, id }) => {
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const [taxFilingRequestDetails, setTaxFilingRequestDetails] = useState();
    const [showFileTaxesModal, setShowFileTaxesModal] = useState(false);
    const [taxFilingDocumentsUpload, setTaxFilingDocumentsUpload] =
        useState(null);
    const [
        isTaxFilingDocumentsUploadValid,
        setIsTaxFilingDocumentsUploadValid,
    ] = useState(false);
    useEffect(() => {
        if (showFileTaxesModal) {
            if (!taxFilingDocumentsUpload) setTaxFilingDocumentsUpload([null]);
        } else {
            setTaxFilingDocumentsUpload(null);
        }
    }, [showFileTaxesModal, taxFilingDocumentsUpload]);
    const getAndSetTaxFilingDetails = () => {
        startApiCall();
        getFiledTaxDetails(id).then(({ data }) => {
            setTaxFilingRequestDetails(data);
            finishApiCall();
        });
    };
    useEffect(() => {
        if (taxFilingDocumentsUpload) {
            let isValid = true;
            for (let file of taxFilingDocumentsUpload) {
                if (
                    !file ||
                    !["application/pdf", "image/jpeg", "image/png"].includes(
                        file.type
                    )
                ) {
                    isValid = false;
                    break;
                }
            }
            setIsTaxFilingDocumentsUploadValid(isValid);
        }
    }, [taxFilingDocumentsUpload]);
    useEffect(() => {
        getAndSetTaxFilingDetails();
    }, []);
    const onSubmit = async () => {
        startApiCall();
        const requestData = {
            requestId: taxFilingRequestDetails.id,
            documents: [],
        };
        const presignedUrlsData = [];
        taxFilingDocumentsUpload.forEach((file) => {
            if (file) {
                const splittedName = file.name.split(".");
                presignedUrlsData.push({
                    extension: splittedName[splittedName.length - 1],
                    contentType: file.type,
                });
            }
        });
        const { data: presignedUrls } = await generatePresignedUrls(
            presignedUrlsData
        );
        for (const [index, file] of taxFilingDocumentsUpload.entries()) {
            if (file) {
                requestData.documents.push(presignedUrls[index].key);
                await uploadFileFromPresignedUrl({
                    upload_url: presignedUrls[index].url,
                    file,
                    fileType: file.type,
                });
            }
        }
        await fileTaxAdmin(requestData);
        successToast("Tax filed successfully!");
        setShowFileTaxesModal(false);
        getAndSetTaxFilingDetails();
        finishApiCall();
    };
    if (!taxFilingRequestDetails) return null;
    return (
        <Modal
            heading=""
            onClose={onClose}
            style={{
                maxWidth: "70rem",
                maxHeight: "90vh",
                width: "100%",
                overflow: "auto",
            }}
            showButton={false}
        >
            {showFileTaxesModal && (
                <Modal
                    buttonText="file"
                    buttonProps={{
                        isDisabled: !isTaxFilingDocumentsUploadValid,
                    }}
                    heading="File Tax"
                    style={{
                        maxWidth: "50rem",
                        maxHeight: "90vh",
                        width: "100%",
                        overflow: "auto",
                    }}
                    onSubmit={onSubmit}
                    onClose={() => setShowFileTaxesModal(false)}
                >
                    <DocumentUploads>
                        {taxFilingDocumentsUpload &&
                            taxFilingDocumentsUpload.map(
                                (taxFilingDocument, index) => (
                                    <DocumentUploadZoneRemove key={index}>
                                        <DocumentUploadZone
                                            style={{ flexGrow: "1" }}
                                            file={taxFilingDocument}
                                            setFile={(file) =>
                                                setTaxFilingDocumentsUpload(
                                                    (prev) => [
                                                        ...prev.slice(0, index),
                                                        file,
                                                        ...prev.slice(
                                                            index + 1
                                                        ),
                                                    ]
                                                )
                                            }
                                        />
                                        <DocumentUploadRemove
                                            isDisabled={
                                                index === 0 &&
                                                taxFilingDocumentsUpload.length ===
                                                    1
                                            }
                                            onClick={() =>
                                                taxFilingDocumentsUpload.length !==
                                                    1 &&
                                                setTaxFilingDocumentsUpload(
                                                    (prev) => [
                                                        ...prev.slice(0, index),
                                                        ...prev.slice(
                                                            index + 1
                                                        ),
                                                    ]
                                                )
                                            }
                                        >
                                            <IoMdClose />
                                        </DocumentUploadRemove>
                                    </DocumentUploadZoneRemove>
                                )
                            )}
                    </DocumentUploads>
                    <Button
                        padding="1rem"
                        style={{
                            marginTop: "2rem",
                            fontSize: "2.5rem",
                            display: "flex",
                            alignItems: "center",
                        }}
                        onClick={() =>
                            setTaxFilingDocumentsUpload((prev) => [
                                ...prev,
                                null,
                            ])
                        }
                    >
                        <BsFillFileEarmarkPlusFill />
                    </Button>
                </Modal>
            )}
            <Table style={{ width: "100%", marginBottom: "2.5rem" }}>
                <TableRow>
                    <Th>Type Of Detail</Th>
                    <Th>Detail</Th>
                </TableRow>
                <TableRow>
                    <Th>Name</Th>
                    <Td>
                        {`${taxFilingRequestDetails.first_name} ${taxFilingRequestDetails.last_name}`}
                    </Td>
                </TableRow>
                <TableRow>
                    <Th>Email</Th>
                    <Td>{taxFilingRequestDetails.email}</Td>
                </TableRow>
                <TableRow>
                    <Th>Phone Number</Th>
                    <Td>{taxFilingRequestDetails.phone_num || "N/A"}</Td>
                </TableRow>
                <TableRow>
                    <Th>State</Th>
                    <Td>{taxFilingRequestDetails.state}</Td>
                </TableRow>
                <TableRow>
                    <Th>Tax Filing Status</Th>
                    <Td>{taxFilingRequestDetails.status}</Td>
                </TableRow>
                <TableRow>
                    <Th>Filed</Th>
                    <Td>
                        <TaxesFiled>
                            {taxFilingRequestDetails.is_filed ? "Yes" : "No"}
                            {taxFilingRequestDetails.is_filed ||
                            !taxFilingRequestDetails.is_paid ||
                            !taxFilingRequestDetails.documents_uploaded ||
                            !taxFilingRequestDetails.questions_answered ? (
                                <></>
                            ) : (
                                <Button
                                    onClick={() => setShowFileTaxesModal(true)}
                                    padding="1rem 1.5rem"
                                >
                                    file taxes
                                </Button>
                            )}
                        </TaxesFiled>
                    </Td>
                </TableRow>
                <TableRow>
                    <Th>Paid</Th>
                    <Td>{taxFilingRequestDetails.is_paid ? "Yes" : "No"}</Td>
                </TableRow>
                {taxFilingRequestDetails.status.toLowerCase() ===
                "individual" ? (
                    <>
                        <TableRow>
                            <Th>Resident</Th>
                            <Td>{taxFilingRequestDetails.resident}</Td>
                        </TableRow>
                        <TableRow>
                            <Th>Marital Status</Th>
                            <Td>{taxFilingRequestDetails.marital_status}</Td>
                        </TableRow>
                        <TableRow>
                            <Th>Married Filing Type</Th>
                            <Td>
                                {taxFilingRequestDetails.married_filing_type}
                            </Td>
                        </TableRow>
                        <TableRow>
                            <Th>Sources Of Income</Th>
                            <Td>
                                {taxFilingRequestDetails.sources_of_income.map(
                                    (source_of_income, index) =>
                                        `${source_of_income}${
                                            taxFilingRequestDetails
                                                .sources_of_income.length -
                                                1 ===
                                            index
                                                ? ""
                                                : ", "
                                        }`
                                )}
                            </Td>
                        </TableRow>
                    </>
                ) : (
                    <>
                        <TableRow>
                            <Th>LLC Type</Th>
                            <Td>{taxFilingRequestDetails.llc_type}</Td>
                        </TableRow>
                        <TableRow>
                            <Th>Is Foreign Owned?</Th>
                            <Td>
                                {taxFilingRequestDetails.is_foreign_owned
                                    ? "Yes"
                                    : "No"}
                            </Td>
                        </TableRow>
                    </>
                )}
            </Table>
            <Tabs
                data={[
                    {
                        name: "Answers",
                        component: (
                            <QuestionAnswers>
                                {taxFilingRequestDetails.answers
                                    ? taxFilingRequestDetails.answers.map(
                                          ({ question, answer }, index) => (
                                              <QuestionAnswer key={index}>
                                                  <Question>
                                                      {question}
                                                  </Question>
                                                  <Answer>{answer}</Answer>
                                              </QuestionAnswer>
                                          )
                                      )
                                    : "No Questions Answered"}
                            </QuestionAnswers>
                        ),
                    },
                    {
                        name: "Document Uploads",
                        component: (
                            <DocumentUploads>
                                {taxFilingRequestDetails.documents
                                    ? taxFilingRequestDetails.documents.map(
                                          ({ name, url }, index) => (
                                              <DocumentUpload key={index}>
                                                  <DocumentUploadName>
                                                      {name}
                                                  </DocumentUploadName>
                                                  <Button href={url}>
                                                      Open
                                                  </Button>
                                              </DocumentUpload>
                                          )
                                      )
                                    : "No Documents Uploaded"}
                            </DocumentUploads>
                        ),
                    },
                ]}
            />
        </Modal>
    );
};

export default TaxFilingRequestDetails;
