import styled from "styled-components";

export const QuestionAnswers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const QuestionAnswer = styled.div``;

export const Question = styled.div`
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #55bb53;
`;

export const Answer = styled.div``;

export const DocumentUploads = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const DocumentUpload = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
`;

export const TaxesFiled = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const DocumentUploadName = styled.div``;

export const DocumentUploadZoneRemove = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`;

export const DocumentUploadRemove = styled.div`
    flex-shrink: 0;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #fff;
    background: #55bb53;
    border-radius: 50%;
    ${({ isDisabled }) =>
        isDisabled
            ? `
                opacity: 0.5;
              `
            : `
                cursor: pointer;
              `}
`;
