import React, { useEffect, useState } from "react";
import { Container, Error } from "./styles";

const TextArea = ({
    placeholder,
    value,
    onValidation,
    validationSchema,
    onChange,
    style,
}) => {
    const [error, setError] = useState();
    const validate = async (value) => {
        if (validationSchema) {
            try {
                await validationSchema.validateAsync(value);
                onValidation && onValidation(true);
                setError(null);
            } catch (err) {
                onValidation && onValidation(false);
                setError(err.message);
            }
        }
    };
    const valueChanged = (e) => {
        onChange(e);
        validate(e.target.value);
    };
    useEffect(() => {
        if (value) {
            validate(value);
        }
    }, []);
    return (
        <>
            <Container
                style={style}
                isValid={!Boolean(error)}
                placeholder={placeholder}
                value={value}
                onChange={valueChanged}
            />
            {error && <Error>{error}</Error>}
        </>
    );
};

export default TextArea;
