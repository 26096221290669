import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
`;

export const BackArrow = styled.div`
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
    color: #fff;
    font-size: 3rem;
    cursor: pointer;
    @media screen and (max-width: 800px) {
        color: #000;
    }
`;

export const Image = styled.div`
    width: 40%;
    background: #55bb53;
    /* background-image: url(${({ src }) => src});
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center; */
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const Form = styled.div`
    width: 60%;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    position: relative;
    padding: 3rem;
    & > div {
        max-width: 40rem;
        width: 100%;
        margin: auto;
    }
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;
