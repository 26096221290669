import React from "react";
import Button from "../../Global/Button/Button";
import { Container, Content, Heading, HeadingGetStarted, Icon } from "./styles";

const ReadyToGetStarted = () => {
    return (
        <Container>
            <Content>
                <HeadingGetStarted>
                    <Heading>Get Maximum Refund Now!</Heading>
                    <Button to="/get-my-taxes-filed" padding="1.7rem 3.5rem">
                        Begin my tax filing process
                    </Button>
                </HeadingGetStarted>
                <Icon
                    src={`${process.env.PUBLIC_URL}/assets/dashboard/dashboard_bg.png`}
                    alt="File my taxes now"
                />
            </Content>
        </Container>
    );
};

export default ReadyToGetStarted;
