import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 4rem;
    max-width: 100rem;
    margin: auto;
    align-items: center;
    @media screen and (max-width: 650px) {
        flex-direction: column;
    }
`;

export const Left = styled.div`
    text-align: center;
    font-weight: 700;
    flex-shrink: 0;
    flex: 1;
    min-width: 28rem;
    @media screen and (max-width: 650px) {
        order: 1;
    }
`;

export const ProfilePic = styled.div`
    background: url("${({ src }) => src}");
    margin: auto;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
`;

export const Name = styled.div`
    color: #0b2a41;
    font-size: 2.5rem;
    line-height: 30px;
    margin: 0.5rem 0;
`;

export const Position = styled.div`
    color: #55bb53;
    font-size: 2.2rem;
    line-height: 26px;
    margin-bottom: 1rem;
`;
export const CompanyTitle = styled.div`
    color: #55bb53;
    font-size: 1.8rem;
    line-height: 20px;
    margin-bottom: 1rem;
`;

export const Company = styled.img`
    width: 10rem;
    @media screen and (max-width: 650px) {
        max-width: 24rem;
        width: 100%;
    }
`;

export const Right = styled.div`
    position: relative;
`;

export const QuoteIcon = styled.div`
    color: #55bb53;
    font-size: 9rem;
    position: absolute;
    opacity: 0.2;
    top: -4rem;
    left: -4rem;
`;

export const Review = styled.div`
    font-size: 1.8rem;
`;

export const WeHelpedWith = styled.div`
    text-align: center;
    margin: 4rem 0 2rem 0;
    & span {
        color: #55bb53;
        font-weight: bold;
    }
`;

export const ExperienceIndustry = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: left;
    margin-top: 2rem;
    & > div {
        padding: 1rem 1.5rem;
        border-radius: 10px;
        border: 1px dashed;
        font-weight: bold;
    }
`;

export const Experience = styled.div`
    color: #0b2a41;
    background: rgb(245, 251, 255);
`;

export const Industry = styled.div`
    color: #55bb53;
    background: rgba(85, 187, 83, 0.1);
`;
