import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import UserHome from "./UserHome";
import AdminHome from "./AdminHome";
import { useEffect } from "react";

const Home = () => {
    const [userDetails] = useContext(AuthContext);
    useEffect(() => {
        document.title = "Dashboard - Home • Eazytaxes";
    }, []);
    if (!userDetails) return null;
    return userDetails.user_type === "user" ? <UserHome /> : <AdminHome />;
};

export default Home;
