import React from "react";
import {
    BlogDateOpen,
    Container,
    Content,
    Date,
    Desc,
    Image,
    Open,
    Title,
    TitleDesc,
} from "./styles";
import { BsArrowRight } from "react-icons/bs";

const Blog = ({ image, link, title, published, desc }) => {
    return (
        <Container>
            <Image src={image} />
            <Content>
                <TitleDesc>
                    <a href={link} target="_blank" rel="noreferrer">
                        <Title>{title}</Title>
                    </a>
                    <Desc>{desc}</Desc>
                </TitleDesc>
                <BlogDateOpen>
                    <Date>{published}</Date>
                    <a href={link} target="_blank" rel="noreferrer">
                        <Open>
                            <BsArrowRight />
                        </Open>
                    </a>
                </BlogDateOpen>
            </Content>
        </Container>
    );
};

export default Blog;
