import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    text-align: center;
    max-width: 45rem;
    margin: auto;
`;

export const Heading = styled.div`
    font-size: 4rem;
    font-weight: bold;
`;

export const Desc = styled.div`
    font-size: 2rem;
    margin: 2.5rem 0;
    line-height: 30px;
    & span {
        font-weight: bold;
        color: #55bb53;
    }
`;
