import axios from "axios";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/contact`,
    headers: {
        "Content-Type": "application/json",
    },
};

const contact = axios.create(config);

export const sendQuery = async (data) => await contact.post("/", data);
