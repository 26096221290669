import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Container = styled.button`
    user-select: none;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 8px;
    padding: ${({ padding }) => padding};
    background: ${({ bg }) => bg};
    color: ${({ color }) => color};
    &:hover {
        background: ${({ hoverBg, isDisabled }) =>
        hoverBg && !isDisabled ? hoverBg : ""};
        color: ${({ hoverColor, isDisabled }) =>
        hoverColor && !isDisabled ? hoverColor : ""};
    }
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s;
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
    ${({ isDisabled }) => (isDisabled ? "opacity: 0.5;" : "")}
`;

const Button = ({
    to,
    href,
    onClick,
    style,
    linkStyle,
    children,
    padding = "1.2rem 3rem",
    color = "#fff",
    bg = "#55bb53",
    isDisabled,
    hoverBg,
    hoverColor,
}) => {
    const buttonElem = (
        <Container
            isDisabled={isDisabled}
            onClick={
                isDisabled
                    ? (e) => {
                        e.preventDefault();
                    }
                    : onClick
            }
            style={style}
            padding={padding}
            bg={bg}
            hoverBg={hoverBg}
            color={color}
            hoverColor={hoverColor}
        >
            {children}
        </Container>
    );
    if (to)
        return (
            <NavLink style={linkStyle} to={to}>
                {buttonElem}
            </NavLink>
        );
    else if (href)
        return (
            <a style={linkStyle} href={href} target="_blank" rel="noreferrer">
                {buttonElem}
            </a>
        );
    else return buttonElem;
};

export default Button;
