import React, { useEffect, useState } from "react";

import { Container, Heading, Desc, Buttons } from "./styles";
import Reviews from "../../../Components/Home/Reviews/Reviews";
import Button from "../../../Components/Global/Button/Button.jsx";

const SmallBusiness = () => {
  useEffect(() => {
    document.title = "Services • Eazytaxes";
  }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isCalendlyOpen ? "hidden" : "auto";
  }, [isCalendlyOpen]);

  return (
    <Container>
      <Heading>
        Expert <span>Tax Preparation</span> Services for Small Business Owners
      </Heading>
      <Desc>
        Our tax preparation service for small businesses in the USA is designed
        to help you navigate the complex and often overwhelming world of tax
        compliance. Whether you're a sole proprietor, partnership, or
        corporation, we have the expertise and resources to ensure that your tax
        returns are accurate, timely, and in compliance with all applicable laws
        and regulations.
        <br /> <br />
        We start by gathering all of the necessary documents and information
        needed to complete your tax returns, including financial statements,
        invoices, receipts, and any other relevant documents. We then analyze
        your financial data to determine the best tax strategies and deductions
        available to your business. This includes identifying potential tax
        credits and deductions for business expenses, such as equipment
        purchases, employee benefits, and research and development costs.
        <br /> <br />
        Once we have all of the necessary information, we prepare your tax
        returns using advanced software and tools that ensure accuracy and
        efficiency. We review the returns for errors and omissions, and provide
        you with a detailed report explaining any tax implications and
        opportunities for further tax savings.
        <br /> <br />
        In addition to preparing your tax returns, we also offer ongoing support
        and guidance throughout the year to help you stay compliant and minimize
        your tax liability. This includes providing advice on tax planning and
        reporting requirements, as well as assistance with tax audits and other
        compliance issues.
        <br /> <br />
        With our tax preparation service for small businesses, you can have
        peace of mind knowing that your tax affairs are in good hands. Contact
        us today to learn more about how we can help your business succeed.
      </Desc>
      <Buttons>
        <Button
          to="/get-my-taxes-filed"
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
        >
          Begin my tax filing process
        </Button>
        <Button
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
          bg="transparent"
          color="#55bb53"
          hoverBg="#55bb53"
          hoverColor="#fff"
          onClick={() => setIsCalendlyOpen(true)}
        >
          get free consultation
        </Button>
      </Buttons>
      <Reviews />
    </Container>
  );
};

export default SmallBusiness;
