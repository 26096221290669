import { toast } from "react-toastify";

const toastConfig = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

export const successToast = (msg) => toast.success(msg, toastConfig);

export const errorToast = (msg) => toast.error(msg, toastConfig);
