import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Container, Item } from "./styles";

const List = ({ items }) => {
    return (
        <Container>
            {items.map((item, index) => (
                <Item key={index}>
                    <BsCheckCircleFill
                        style={{ flexShrink: 0, alignSelf: "start", marginTop: "1rem" }}
                        color="#55bb53"
                        fontSize={20}
                    />
                    {item}
                </Item>
            ))}
        </Container>
    );
};

export default List;
