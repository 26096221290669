//user types
export const USER_USER_TYPE = 1;
export const ADMIN_USER_TYPE = 2;

//signup types
export const EMAIL_PASSWORD_LOGIN_SIGNUP_TYPE = 1;
export const GOOGLE_LOGIN_SIGNUP_TYPE = 2;

//resend otp types
export const RESEND_EMAIL_OTP = "email";
export const RESEND_PHONE_NUM_OTP = "phonenum";

//statuses of tax filer
export const INDIVIDUAL_TAX_FILER = 1;
export const LLC_TAX_FILER = 2;
export const C_CORP_S_CORP_TAX_FILER = 3;
export const OTHERS_TAX_FILER = 4;
export const TAX_FILER_STATUSES = [
    INDIVIDUAL_TAX_FILER,
    LLC_TAX_FILER,
    C_CORP_S_CORP_TAX_FILER,
    OTHERS_TAX_FILER,
];

//resident types of tax filer
export const RESIDENT_TAX_FILER = 1;
export const NON_RESIDENT_TAX_FILER = 2;
export const TAX_FILER_RESIDENT_TYPES = [
    RESIDENT_TAX_FILER,
    NON_RESIDENT_TAX_FILER,
];

//marital status types
export const SINGLE_MARTIAL_STATUS = 1;
export const MARRIED_MARTIAL_STATUS = 2;
export const MARITAL_STATUS_TYPES = [
    SINGLE_MARTIAL_STATUS,
    MARRIED_MARTIAL_STATUS,
];

//married filing types
export const MARRIED_FILING_JOINTLY = 1;
export const MARRIED_FILING_SEPARATELY = 2;
export const MARRIED_FILING_TYPES = [
    MARRIED_FILING_JOINTLY,
    MARRIED_FILING_SEPARATELY,
];

//sources of income
export const SALARY_INCOME_SOURCE = 1;
export const INTERESTS_DIVIDENDS_INCOME_SOURCE = 2;
export const SELF_EMPLOYED_INCOME_SOURCE = 3;
export const OTHERS_INCOME_SOURCE = 4;
export const INCOME_SOURCES = [
    SALARY_INCOME_SOURCE,
    INTERESTS_DIVIDENDS_INCOME_SOURCE,
    SELF_EMPLOYED_INCOME_SOURCE,
    OTHERS_INCOME_SOURCE,
];

//llc types
export const SINGLE_MEMBER_LLC = 1;
export const MULTIPLE_MEMBER_LLC = 2;
export const LLC_TYPES = [SINGLE_MEMBER_LLC, MULTIPLE_MEMBER_LLC];

//tax filing request prices
export const TAX_FILING_200 = "price_1M2fDKJC9ZeG8NIJTC9EX7YP";
export const TAX_FILING_300 = "price_1M2fDKJC9ZeG8NIJJ8fOqTTF";
export const TAX_FILING_500 = "price_1M2fDKJC9ZeG8NIJNzJwEdeA";

//get filed taxes criterias
export const NOT_PAID = 1;
export const PAID_BUT_NOT_ANSWERED = 2;
export const PAID_BUT_NOT_UPLOADED = 3;
export const TO_BE_FILED = 4;
export const FILED = 5;
export const FILED_TAXES_CRITERIAS = [
    NOT_PAID,
    PAID_BUT_NOT_ANSWERED,
    PAID_BUT_NOT_UPLOADED,
    TO_BE_FILED,
    FILED,
];
