import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 2rem;
    user-select: none;
    align-items: center;
    justify-content: center;
`;

export const Step = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
`;

export const StepIcon = styled.div`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    flex-shrink: 0;
    ${({ completed, inProgress, pending }) => {
        if (inProgress)
            return `
                background: #433EF5;
                padding: 0.3rem;
                border: 1px solid #433EF5;
                background-clip: content-box;
            `;
        else if (completed)
            return `
                background: #23C174;
            `;
        else if (pending)
            return `
                border: 1px solid #9092a3;
                color: #9092a3;
            `;
    }}
`;

export const StepContent = styled.div``;

export const StepNumber = styled.div`
    text-transform: uppercase;
    color: #9092a3;
    font-size: 1.2rem;
    font-weight: bold;
`;

export const StepName = styled.div`
    font-weight: bold;
    margin: 0.5rem 0;
`;

export const StepStatus = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.4rem 0.8rem;
    display: inline-block;
    border-radius: 0.8rem;
    ${({ completed, inProgress, pending }) => {
        if (inProgress)
            return `
                background: #f2f2ff;
                color: #433ef5;
            `;
        else if (completed)
            return `
                background: rgba(35,193,116, 0.2);
                color: #23C174;
            `;
        else if (pending)
            return `
                border: 1px solid #9092a3;
                color: #9092a3;
            `;
    }}
`;

export const StepSeperator = styled.div`
    flex-grow: 0.5;
    height: 0.4rem;
    background: ${({ completed }) => (completed ? "#23C174" : "#E6EBFB")};
    border-radius: 1rem;
`;
