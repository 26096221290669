import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
    RiCheckboxCircleFill,
    RiCloseCircleFill,
    RiLockPasswordLine,
} from "react-icons/ri";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
    forgotPassword,
    validateForgotPasswordToken,
} from "../../Api/users.api";
import Input from "../../Components/Global/Input/Input";
import {
    Arrow,
    Buttons,
    Container,
    Heading,
    InputGroup,
    SuccessErrorDesc,
    SuccessErrorIcon,
    ToolTip,
} from "./styles";
import PasswordChecklist from "react-password-checklist";
import { createPopper } from "@popperjs/core";
import Button from "../../Components/Global/Button/Button";
import { errorToast, successToast } from "../../toasts";
import "@lottiefiles/lottie-player";
import { LoadingContext } from "../../Context/LoadingContext.js";

const ResetPassword = () => {
    const passwordRef = useRef();
    const tooltipRef = useRef();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isRetypedPasswordValid, setIsRetypedPasswordValid] = useState(false);
    const [password, setPassword] = useState("");
    const [retypedPassword, setRetypedPassword] = useState("");
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const [isTokenValid, setIsTokenValid] = useState();
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        document.title = "Reset Password • Eazytaxes";
        const token = searchParams.get("token");
        validateForgotPasswordToken(token)
            .then(() => {
                setIsTokenValid(true);
                createPopper(passwordRef.current, tooltipRef.current, {
                    placement: "bottom",
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 25],
                            },
                        },
                    ],
                });
            })
            .catch(() => setIsTokenValid(false));
    }, [searchParams]);
    const ShowHidePassword = React.cloneElement(
        isPasswordVisible ? <AiFillEyeInvisible /> : <AiFillEye />,
        {
            onClick: () => setIsPasswordVisible((prev) => !prev),
            fontSize: 25,
            style: {
                marginRight: "0.2rem",
                cursor: "pointer",
            },
        }
    );
    const resetPasswordHandler = async () => {
        try {
            startApiCall();
            await forgotPassword({
                token: searchParams.get("token"),
                newPassword: password,
            });
            successToast(
                "Your password has been changed successfully! You can now login with the new password."
            );
            finishApiCall();
            navigate("/accounts/login");
        } catch (err) {
            errorToast(
                "Your password reset link has expired. Please send a new password reset email!"
            );
            finishApiCall();
            navigate("/accounts/forgot-password");
        }
    };
    return (
        <Container style={isTokenValid ? {} : { maxWidth: "50%" }}>
            {isTokenValid ? (
                <>
                    <Heading>Reset Password</Heading>
                    <InputGroup>
                        <Input
                            ref={passwordRef}
                            onFocus={() => setIsVisible(true)}
                            onBlur={() => setIsVisible(false)}
                            type={isPasswordVisible ? "text" : "password"}
                            button={ShowHidePassword}
                            placeholder="Password"
                            icon={<RiLockPasswordLine />}
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                        />
                        <ToolTip
                            isVisible={isVisible}
                            ref={tooltipRef}
                            role="tooltip"
                        >
                            <PasswordChecklist
                                rules={[
                                    "minLength",
                                    "specialChar",
                                    "number",
                                    "capital",
                                ]}
                                minLength={5}
                                value={password}
                                onChange={setIsPasswordValid}
                                iconComponents={{
                                    ValidIcon: (
                                        <RiCheckboxCircleFill
                                            color="green"
                                            fontSize={22}
                                        />
                                    ),
                                    InvalidIcon: (
                                        <RiCloseCircleFill
                                            color="red"
                                            fontSize={22}
                                        />
                                    ),
                                }}
                            />
                            <Arrow isVisible={isVisible} data-popper-arrow />
                        </ToolTip>
                        <Input
                            type={isPasswordVisible ? "text" : "password"}
                            button={ShowHidePassword}
                            placeholder="Password"
                            icon={<RiLockPasswordLine />}
                            validationSchema={{
                                validateAsync: async (value) => {
                                    if (value !== password)
                                        throw new Error(
                                            "Passwords do not match!"
                                        );
                                },
                            }}
                            onValidation={setIsRetypedPasswordValid}
                            value={retypedPassword}
                            onChange={({ target }) =>
                                setRetypedPassword(target.value)
                            }
                        />
                    </InputGroup>
                    <Buttons>
                        <Button
                            linkStyle={{
                                width: "100%",
                            }}
                            style={{
                                textTransform: "capitalize",
                                width: "100%",
                                fontSize: "1.8rem",
                            }}
                            onClick={resetPasswordHandler}
                            hoverBg="#f5f5f5"
                            hoverColor="#0b2a41"
                            isDisabled={
                                !isPasswordValid || !isRetypedPasswordValid
                            }
                        >
                            Reset Password
                        </Button>
                    </Buttons>
                </>
            ) : (
                <>
                    <SuccessErrorIcon src="https://cdn-icons-png.flaticon.com/512/4847/4847128.png" />
                    <SuccessErrorDesc>
                        This password reset link is invalid. Go to{" "}
                        <NavLink to="/accounts/forgot-password">
                            forgot password
                        </NavLink>{" "}
                        page to generate a new password reset mail.
                    </SuccessErrorDesc>
                </>
            )}
        </Container>
    );
};

export default ResetPassword;
