import styled from "styled-components";

export const Container = styled.div`
    padding: 0 2.5rem;
    background: rgba(255, 255, 255, 0.7);
    max-height: 80rem;
    max-width: 120rem;
    margin: auto;
    display: flex;
    align-items: center;
    & lottie-player {
        width: 100%;
    }
    @media screen and (max-width: 900px) {
        flex-direction: column;
        padding: 5rem 2.5rem;
        & lottie-player {
            width: 70%;
            order: 1;
        }
    }
    @media screen and (max-width: 800px) {
        height: 60rem;
    }
    @media screen and (max-width: 500px) {
        height: 40rem;
    }
`;

export const HeaderContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    @media screen and (max-width: 900px) {
        order: 2;
    }
`;

export const Heading = styled.div`
    font-size: 4rem;
    text-align: center;
    font-weight: 700;
    color: #1b1d21;
    font-family: "Poppins", sans-serif;
    max-width: 70rem;
    margin: auto;
    line-height: 1.2;
    margin-bottom: 4rem;
    & span {
        background: linear-gradient(
            45deg,
            #55bb53 0%,
            #0d71ba 80.42%,
            #0d71ba 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width: 1100px) {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 1000px) {
        font-size: 3rem;
    }
`;
