import { cloneElement, Fragment, isValidElement, useEffect } from "react";
import privacyPolicyPoints from "../../data/privacyPolicyPoints";
import {
    Container,
    Content,
    Heading,
    PrivacyPolicyContent,
    PrivacyPolicyHeading,
    PrivacyPolicyParagraph,
    PrivacyPolicyPoint,
    PrivacyPolicySubHeading,
} from "./styles";

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy • Eazytaxes";
    }, []);
    return (
        <Container>
            <Heading>Privacy Policy</Heading>
            <Content>
                {privacyPolicyPoints.map(({ heading, content }, index) => (
                    <PrivacyPolicyPoint key={index}>
                        <PrivacyPolicyHeading>
                            {index + 1}. {heading}
                        </PrivacyPolicyHeading>
                        <PrivacyPolicyContent>
                            {content.map((contentItem, index) => {
                                if (isValidElement(contentItem))
                                    return cloneElement(contentItem, {
                                        key: index,
                                    });
                                else if (typeof contentItem === "string")
                                    return (
                                        <PrivacyPolicyParagraph key={index}>
                                            {contentItem}
                                        </PrivacyPolicyParagraph>
                                    );
                                else
                                    return (
                                        <Fragment key={index}>
                                            <PrivacyPolicySubHeading>
                                                {contentItem.heading}
                                            </PrivacyPolicySubHeading>
                                            <PrivacyPolicyParagraph>
                                                {contentItem.content}
                                            </PrivacyPolicyParagraph>
                                        </Fragment>
                                    );
                            })}
                        </PrivacyPolicyContent>
                    </PrivacyPolicyPoint>
                ))}
            </Content>
        </Container>
    );
};

export default PrivacyPolicy;
