import styled from "styled-components";

export const Container = styled.div`
    @media screen and (max-width: 800px) {
        max-width: max-content !important;
    }
`;

export const Heading = styled.div`
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
`;

export const Desc = styled.div`
    margin-top: 0.5rem;
    text-align: center;
    color: #60626d;
`;

export const InputGroup = styled.div`
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const ForgotPassword = styled.div`
    text-align: right;
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #0b2a41;
`;

export const Buttons = styled.div`
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
`;

export const Or = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    user-select: none;
    color: #60626d;
`;

export const OrLine = styled.div`
    flex: 1;
    height: 0.01rem;
    background: #60626d;
`;

export const UploadProfilePic = styled.div`
    user-select: none;
    border: 0.2rem dashed #dbdbdb;
    border-radius: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    transition: all 0.2s;
    color: #60626d;
    ${({ fileDropError }) =>
        fileDropError
            ? `
                border-color: red;
                background: rgba(255, 0, 0, 0.3);
              `
            : ""}
    ${({ profilePic }) =>
        profilePic
            ? `
                border-color: #55bb53;
              `
            : ""}
    ${({ fileBeingDragged }) =>
        fileBeingDragged
            ? `
                border-color: #55bb53;
                background: rgba(85,187,83, 0.3);
              `
            : ""}
`;

export const ProfilePic = styled.div`
    width: 8rem;
    height: 8rem;
    background: url("${({ src }) => src}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    border-radius: 50%;
`;

export const UploadProfilePicDesc = styled.div`
    font-size: 1.5rem;
    margin: 1rem 0;
    & span {
        color: #55bb53;
        font-weight: 700;
        cursor: pointer;
    }
`;

export const ImagesSupported = styled.div`
    font-size: 1.2rem;
`;

const arrowArrowBeforeCommonStyles = `
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: inherit;
`;

export const Arrow = styled.div`
    ${({ isVisible }) => isVisible || "display: none;"}
    ${arrowArrowBeforeCommonStyles}
    visibility: hidden;
    &:before {
        ${arrowArrowBeforeCommonStyles}
        visibility: visible;
        content: "";
        transform: rotate(45deg);
    }
`;

export const ToolTip = styled.div`
    z-index: 1;
    ${({ isVisible }) => isVisible || "visibility: hidden;"}
    user-select: none;
    padding: 1.5rem;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 12%);
    border-radius: 0.4rem;
    &[data-popper-placement^="top"] > ${Arrow} {
        bottom: -0.4rem;
    }
    &[data-popper-placement^="bottom"] > ${Arrow} {
        top: -0.4rem;
    }
    &[data-popper-placement^="left"] > ${Arrow} {
        right: -0.4rem;
    }
    &[data-popper-placement^="right"] > ${Arrow} {
        left: -0.4rem;
    }
`;

export const SuccessErrorIcon = styled.img`
    width: 50%;
    margin: auto;
    display: block;
`;

export const SuccessErrorDesc = styled.div`
    text-align: center;
    font-size: 2.5rem;
    margin-top: 2rem;
    line-height: 40px;
    & span,
    & a {
        color: #55bb53;
        font-weight: bold;
        cursor: pointer;
    }
    @media screen and (max-width: 800px) {
        font-size: 2rem;
        line-height: 30px;
    }
`;

export const ResendButton = styled.button`
    font-size: 1.6rem;
    color: #55bb53;
    font-weight: bold;
`;
