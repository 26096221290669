import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 2.5rem;
    justify-content: row;
    max-width: 140rem;
    padding: 4rem 2.5rem;
    margin: 0 auto;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        gap: 0;
        padding: 4rem 1rem;
    }
`;

export const TitleImage = styled.div`
    flex: 1.8;
    // max-width: 70rem;
    text-align: center;
    @media screen and (max-width: 900px) {
        & lottie-player {
            display: none;
        }
    }
`;

export const Image = styled.img`
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    // max-width: 70rem;
    @media screen and (max-width: 900px) {
        max-width: 50rem;
    }
`;

export const Title = styled.div`
    font-size: 3.5rem;
    // max-width: 60rem;
    margin: auto;
    font-weight: 500;
    line-height: 40px;
    & span {
        background: linear-gradient(
            45deg,
            #55bb53 0%,
            #0d71ba 80.42%,
            #0d71ba 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media screen and (max-width: 1100px) {
        font-size: 3.5rem;
        line-height: 50px;
    }
    @media screen and (max-width: 900px) {
        font-size: 3rem;
        line-height: 40px;
    }
`;

export const LoginContainer = styled.div`
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    // max-width: 50rem;
    padding-left: 2rem;
    @media screen and (max-width: 900px) {
        margin-top: 3rem;
    }
`;

export const StepsProgress = styled.div``;

export const Step = styled.div`
    max-width: 50rem;
    margin: 4rem auto;
    padding: 4rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    @media screen and (max-width: 500px) {
        padding: 3rem;
    }
`;

export const StepHeading = styled.h2`
    text-align: center;
    margin-bottom: 4rem;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 4rem;
`;
