import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
    PricingContainer,
    CutPrice,
    Price,
    Feature,
    FeatureContent,
    FeatureHeader,
    Heading,
    StartFilingButton,
    FeatureImage,
    FeatureSubText,
    Text
} from "./styles";


const Pricing = () => {
    useEffect(() => {
        document.title = "Pricing • Eazytaxes";
    }, []);

    const navigate = useNavigate();


    const pricing = [
        {
            title: "Salaried",
            cutPrice: "$149",
            price: "$74*",
            image: `${process.env.PUBLIC_URL}/assets/price/img1.jpg`,
            subtext: "$99 for married filing jointly"
        },
        {
            title: "Self Employed",
            cutPrice: "$199",
            price: "$119",
            image: `${process.env.PUBLIC_URL}/assets/price/img2.jpg`,
        },
        {
            title: "LLCs",
            cutPrice: "$299",
            price: "$249",
            image: `${process.env.PUBLIC_URL}/assets/price/img3.jpg`,
        },
        {
            title: "C/S-Corp",
            cutPrice: "$399",
            price: "$349",
            image: `${process.env.PUBLIC_URL}/assets/price/img4.jpg`,

        },
    ];

    return (
        <React.Fragment>
            <Heading>
                <span>Fixed Fee - No Hidden Cost</span>
            </Heading>
            <PricingContainer>
                {pricing.map(({ title, cutPrice, price, image, subtext }, index) => (
                    <Feature key={index} style={{ gridArea: `feature${index + 1}` }}>
                        <FeatureImage src={image} />
                        <FeatureHeader>{title}</FeatureHeader>
                        <FeatureContent>
                            <CutPrice>{cutPrice}</CutPrice>
                            <Price>{price}</Price>
                        </FeatureContent>
                        {subtext ? <Text>{subtext}</Text> : <FeatureSubText />}
                        <StartFilingButton onClick={() => navigate("get-my-taxes-filed")} >
                            Start Filing
                        </StartFilingButton>
                    </Feature>

                ))}
            </PricingContainer>
        </React.Fragment>
    );
};

export default Pricing;
