import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "Components/Global/Button/Button";
import {
    Container,
    Content,
    HamMenuButton,
    HeaderLink,
    HeaderLinks,
    LoginSignup,
    LoginSignupHamMenu,
    Logo,
    DropDownMenu,
    HeaderContent,
} from "./styles";
import { AuthContext } from "Context/AuthContext.js";
import { logout } from "Api/users.api";
import { successToast } from "toasts.js";
import { CgMenu } from "react-icons/cg";
import { LoadingContext } from "Context/LoadingContext";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

const Header = ({ openHamMenu }) => {
    const [user, refetchUserDetails] = useContext(AuthContext);
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const headerLinks = [
        {
            to: "/",
            text: "Home",
        },
        {
            to: "/about-us",
            text: "About Us",
        },
        {
            to: "/services",
            text: "Services",
        },
        {
            to: "/contact-us",
            text: "Contact Us",
        },
        {
            to: "/pricing",
            text: "Pricing",
        },
        {
            to: "/get-my-taxes-filed",
            text: "File My Taxes Now",
        },
    ];

    const items = [
        {
            label: "Salaried Professional",
            key: "1",
            link: "/services/salaried-professional",
        },
        {
            label: "Ebay, Shopify, Amazon Sellers",
            key: "2",
            link: "/services/ebay-shopify-amazon-sellers",
        },
        {
            label: "Small Business Owners",
            key: "3",
            link: "/services/small-business-owners",
        },
        {
            label: "Students",
            key: "4",
            link: "/services/students",
        },
    ];

    const onClick = ({ key }) => {
        const obj = items.find((item) => item.key === key);
        // navigate to link
        navigate(obj.link);
    };

    const logoutHandler = async () => {
        startApiCall();
        await logout();
        localStorage.removeItem("authToken");
        refetchUserDetails();
        successToast("Logged out successfully!");
        finishApiCall();
    };
    console.log({ pathname })
    return (
        <>
            {!pathname.startsWith("/dashboard") &&
                !pathname.startsWith("/accounts") && !pathname.startsWith("/get-my-taxes-filed") ? (
                <HeaderContent>
                    <span>Accepting returns for FY 2022. </span> &nbsp;&nbsp;
                    {
                        <NavLink to={"/get-my-taxes-filed"}>
                            <u>File Early</u> &nbsp;
                        </NavLink>
                    } to enjoy Best Prices
                </HeaderContent>
            ) : null}
            <Container>
                <Content>
                    <NavLink to="/">
                        <Logo
                            alt="eazytaxes.com"
                            src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
                        />
                    </NavLink>
                    <HeaderLinks>
                        {headerLinks.map(({ to, text }, index) => {
                            if (text === "Services") {
                                return (
                                    <Dropdown
                                        menu={{
                                            rootClassName: DropDownMenu,
                                            items,
                                            onClick,
                                        }}
                                    >
                                        <HeaderLink>{text}</HeaderLink>
                                    </Dropdown>
                                );
                            } else if (text === "File My Taxes Now" && pathname === "/") {
                                return null
                            }
                            else {
                                return (
                                    <NavLink key={index} to={to}>
                                        <HeaderLink>{text}</HeaderLink>
                                    </NavLink>
                                );
                            }
                        })}
                    </HeaderLinks>
                    <LoginSignupHamMenu>
                        <LoginSignup>
                            {user ? (
                                <>
                                    {!pathname.startsWith("/dashboard") ? (
                                        <Button
                                            to="/dashboard/"
                                            hoverBg="#f5f5f5"
                                            hoverColor="#0b2a41"
                                        >
                                            Go To Dashboard
                                        </Button>
                                    ) : null}
                                    <Button
                                        onClick={logoutHandler}
                                        hoverBg="#f5f5f5"
                                        hoverColor="#0b2a41"
                                    >
                                        Logout
                                    </Button>
                                </>
                            ) : (

                                <>
                                    <Button
                                        to="/accounts/login"
                                        hoverBg="#f5f5f5"
                                        hoverColor="#0b2a41"
                                    >
                                        Login
                                    </Button>
                                    {!pathname.startsWith("/get-my-taxes-filed") ?

                                        <Button
                                            to="/accounts/signup"
                                            hoverBg="#f5f5f5"
                                            hoverColor="#0b2a41"
                                        >
                                            Signup
                                        </Button>
                                        : null}
                                </>
                            )}
                        </LoginSignup>
                        <HamMenuButton onClick={openHamMenu}>
                            <CgMenu />
                        </HamMenuButton>
                    </LoginSignupHamMenu>
                </Content>
            </Container>
        </>
    );
};

export default Header;
