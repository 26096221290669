import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";
import { Container, Main } from "./styles";
import { AuthContext } from "../../Context/AuthContext.js";

const Dashboard = () => {
    const [user] = useContext(AuthContext);
    if (!user) return null;
    return (
            user.user_type === "admin" ? (
                <Container>
                    <SideBar />
                    <Main>
                        <Outlet />
                    </Main>
                </Container>
            ) : (
                <Outlet />
            )
    );
};

export default Dashboard;
