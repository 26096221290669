import React from "react";
import { HiOutlineDocumentText } from "react-icons/hi";
import { RiTeamLine } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import {
    Container,
    Content,
    Feature,
    FeatureContent,
    FeatureIcon,
    FeatureHeader,
    Header
} from "./styles";

const Features = () => {
    const features = [
        {
            icon: `${process.env.PUBLIC_URL}/assets/home/cards/card1.png`,
            header: "Personalized Service",
            content: "We offer the personalized service of a CPA firm, with the convenience and affordability of a DIY software."
        },
        {
            icon: `${process.env.PUBLIC_URL}/assets/home/cards/card2.png`,
            header: "100% Accuracy Guaranteed",
            content: "We ensure that you do not miss out on deductions or credit, and guarantee maximum refund."
        },
        {
            icon: `${process.env.PUBLIC_URL}/assets/home/cards/card3.png`,
            header: "Upfront Transparent pricing",
            content: "One guaranteed fees which includes tax preparation with all applicable forms, state taxes, annual fees (For LLCs)."
        },
        {
            icon: `${process.env.PUBLIC_URL}/assets/home/cards/card4.png`,
            header: "Data Privacy & Security",
            content: "We keep your data safe through multiple layers of encryption and advanced security measures."
        }
    ];
    return (
        <Container>
            <Header>Reasons why the top&nbsp;<span>professionals</span> &nbsp;prefer us!</Header> 
            <Content>
                {features.map(({ icon, header, content }, index) => (
                    <Feature
                        key={index}
                        style={{ gridArea: `feature${index + 1}` }}
                    >
                        <FeatureIcon src={icon} />
                        <FeatureHeader>{header}</FeatureHeader>
                        <FeatureContent>{content}</FeatureContent>
                    </Feature>
                ))}
            </Content>
        </Container>
    );
};

export default Features;
