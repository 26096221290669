import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Global/Button/Button";
import { Container, Content, Desc, Heading } from "./styles";

const PaymentSuccessfull = () => {
    const navigate = useNavigate();
    const [secondsLeft, setSecondsLeft] = useState(5);
    useEffect(() => {
        if (secondsLeft === 0) navigate("/dashboard/");
        else {
            setTimeout(() => setSecondsLeft((prev) => prev - 1), 1000);
        }
    }, [secondsLeft, navigate]);
    useEffect(() => {
        document.title = "Dashboard - Payment Successfull • Eazytaxes";
    }, []);
    return (
        <Container>
            <Content>
                <lottie-player
                    style={{ width: "60%", margin: "auto" }}
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets2.lottiefiles.com/packages/lf20_vuliyhde.json"
                ></lottie-player>
                <Heading>Payment Successfull</Heading>
                <Desc>
                    Your payment was successfull. We are now redirecting you to
                    dashboard in <span>{secondsLeft} seconds</span>.
                </Desc>
                <Button
                    to="/dashboard/"
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                    padding="1.4rem 2.5rem"
                    style={{ fontSize: "1.8rem" }}
                >
                    Redirect Now
                </Button>
            </Content>
        </Container>
    );
};

export default PaymentSuccessfull;
