import axios from "axios";
import { refreshAccessToken } from "./users.api";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/questions`,
    headers: {
        "Content-Type": "application/json",
    },
};

const questions = axios.create(config);
const authenticatedQuestions = axios.create(config);

authenticatedQuestions.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            const accessToken = JSON.parse(authToken).accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

authenticatedQuestions.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            !error.config.retry
        ) {
            error.config.retry = true;
            const accessToken = await refreshAccessToken();
            localStorage.setItem(
                "authToken",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("authToken")),
                    accessToken,
                })
            );
            return authenticatedQuestions(error.config);
        }
        return Promise.reject(error);
    }
);

const getAllQuestions = async () => await authenticatedQuestions.get("/");

const editQuestion = async ({ id, ...data }) =>
    await authenticatedQuestions.patch(`/${id}`, data);

const createNewQuestion = async (data) =>
    await authenticatedQuestions.post("/", data);

const updateQuestionStatus = async ({ id, active }) =>
    await authenticatedQuestions.patch(`/${id}/status`, { active });

const updateQuestionOptionalStatus = async ({ id, optional }) =>
    await authenticatedQuestions.patch(`/${id}/optional`, { optional });

export {
    getAllQuestions,
    editQuestion,
    createNewQuestion,
    updateQuestionStatus,
    updateQuestionOptionalStatus,
};
