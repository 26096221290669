import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;
    grid-area: sidebar;
    padding: 4rem 2rem;
`;

export const Logo = styled.img`
    width: 70%;
    display: block;
    margin: auto;
    margin-bottom: 5rem;
`;

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Link = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 2.5rem;
    padding: 1.6rem 2.4rem;
    border-radius: 2rem;
    color: #637381;
    transition: 0.2s all;
    ${({ isActive }) =>
        isActive
            ? `
                color: #55bb53;
                background: rgba(85, 187, 83, 20%);
              `
            : `
                &:hover {
                    background: rgba(145, 158, 171, 8%);
                }
              `}
`;

export const LinkIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 3.5rem;
`;

export const LinkText = styled.div`
    font-size: 1.8rem;
    font-weight: bold;
`;
