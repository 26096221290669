import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;
