import Joi from "joi";

const requiredValidationSchema = (fieldName) =>
    Joi.string()
        .max(50)
        .required()
        .messages({
            "string.empty": `${fieldName} is a required field.`,
            "string.max": `${fieldName} should have a maximum length of {#limit}`,
        });

export default requiredValidationSchema;
