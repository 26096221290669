import React from "react";
import {
    Container,
    Content,
    Heading,
    Service,
    ServiceDesc,
    ServiceHeading,
    ServiceIcon,
} from "./styles";

const ServicesForYou = () => {
    const services = [
        {
            icon: "https://cdn-icons-png.flaticon.com/512/3135/3135706.png",
            heading: "Salaried Professionals",
            desc: "Our tax preparation service ensures that your taxes are accurately and efficiently completed, allowing you to focus on your career and personal life. Our team of experienced professionals will handle all the details, ensuring that you receive all the deductions and credits you are entitled to.",
        },
        {
            icon: "https://cdn-icons-png.flaticon.com/512/1728/1728925.png",
            heading: "EBay, Shopify and Amazon FBA Sellers",
            desc: "We provide tax preparation services specifically tailored to eBay, Shopify, and Amazon FBA sellers in the USA. Our team of experienced accountants will handle all of your tax needs, ensuring that your business is fully compliant with all state and federal regulations.",
        },
        {
            icon: "https://cdn-icons-png.flaticon.com/512/2761/2761035.png",
            heading: "Small Businesses",
            desc: "We’ve helped hundreds of small business owners with their taxes — and we can help you too. We help small business owners ensure compliance and potentially save money by maximizing deductions and credits. We can help you prepare the books of accounts as well.",
        },
        {
            icon: "https://cdn-icons-png.flaticon.com/512/571/571935.png",
            heading: "Corporate Structuring And Filing",
            desc: "If you are an entrepreneur with a US business, whether it be a 100% owned company or a joint venture, you will need to file either 1065, 1120, or 1120S for your LLC, INC, or S Corporation. Seek our expert services for the same.",
        },
    ];
    return (
        <Container>
            <Heading>
                Online tax filing <span>services</span> for you
            </Heading>
            <Content>
                {services.map(({ icon, heading, desc }, index) => (
                    <Service key={index}>
                        <ServiceIcon src={icon} />
                        <ServiceHeading>{heading}</ServiceHeading>
                        <ServiceDesc>{desc}</ServiceDesc>
                    </Service>
                ))}
            </Content>
        </Container>
    );
};

export default ServicesForYou;
