import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
    getFiledTaxes,
    getTotalTaxesFiled,
} from "../../../../../Api/filetaxes.api";
import {
    FILED,
    INDIVIDUAL_TAX_FILER,
    LLC_TAX_FILER,
    NOT_PAID,
    PAID_BUT_NOT_ANSWERED,
    PAID_BUT_NOT_UPLOADED,
    TO_BE_FILED,
} from "../../../../../data/constants";
import { Container } from "./styles";
import { Table, TableRow, Td, Th } from "../../../../../globalStyles";
import TaxFilingRequestDetails from "../TaxFilingRequestDetails/TaxFilingRequestDetails";
import { LoadingContext } from "../../../../../Context/LoadingContext.js";

const TaxFilingRequestTable = () => {
    const lastPage = useRef(null);
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const [filedTaxes, setFiledTaxes] = useState([]);
    const [totalTaxesFiled, setTotalTaxesFiled] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [criteria, setCriteria] = useState(0);
    const [cursors, setCursors] = useState(null);
    const [statusOfTaxFiler, setStatusOfTaxFiler] = useState(null);
    const [showDetails, setShowDetails] = useState({
        state: false,
        id: null,
    });
    useEffect(() => {
        startApiCall();
        getTotalTaxesFiled({
            criteria,
            status: statusOfTaxFiler,
        }).then((res) => {
            setTotalTaxesFiled(res.data.total);
            finishApiCall();
        });
    }, [criteria, statusOfTaxFiler]);
    useEffect(() => {
        startApiCall();
        let direction = "forward";
        if (lastPage.current) {
            if (lastPage.current < currentPage) direction = "forward";
            else direction = "backward";
        }
        getFiledTaxes({
            criteria,
            status: statusOfTaxFiler,
            limit: resultsPerPage,
            cursor: lastPage.current && cursors && cursors[direction],
            direction,
        }).then((res) => {
            setFiledTaxes(res.data.rows);
            setCursors(res.data.cursors);
            finishApiCall();
        });
    }, [criteria, statusOfTaxFiler, resultsPerPage, currentPage]);
    const totalPages = Math.ceil(totalTaxesFiled / resultsPerPage);
    return (
        <Container>
            {showDetails.state && (
                <TaxFilingRequestDetails
                    id={showDetails.id}
                    onClose={() => setShowDetails({ id: null, state: false })}
                />
            )}
            <div>
                {(currentPage - 1) * resultsPerPage + 1}-
                {(currentPage - 1) * resultsPerPage + filedTaxes.length} of{" "}
                {totalTaxesFiled}
            </div>
            <Table>
                <thead>
                    <TableRow>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Status</Th>
                        <Th>Filed</Th>
                        <Th>Paid</Th>
                        <Th>Questions Answered</Th>
                        <Th>Documents Uploaded</Th>
                        <Th>Total Price</Th>
                    </TableRow>
                </thead>
                <tbody>
                    {filedTaxes.map((filedTax) => (
                        <TableRow
                            key={filedTax.id}
                            onClick={() =>
                                setShowDetails({ id: filedTax.id, state: true })
                            }
                        >
                            <Td>{filedTax.id}</Td>
                            <Td>{`${filedTax.first_name} ${filedTax.last_name}`}</Td>
                            <Td>{`${filedTax.email}`}</Td>
                            <Td>{`${
                                filedTax.status === LLC_TAX_FILER
                                    ? "LLC"
                                    : "Individual"
                            }`}</Td>
                            <Td>{`${filedTax.is_filed}`}</Td>
                            <Td>{`${filedTax.is_paid}`}</Td>
                            <Td>{`${filedTax.questions_answered}`}</Td>
                            <Td>{`${filedTax.documents_uploaded}`}</Td>
                            <Td>{`${filedTax.total_price}`}</Td>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            <select
                value={resultsPerPage}
                onChange={(e) => {
                    setResultsPerPage(+e.target.value);
                    lastPage.current = null;
                    setCurrentPage(1);
                }}
            >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
            </select>
            <select
                value={criteria}
                onChange={(e) => {
                    setCriteria(+e.target.value);
                    lastPage.current = null;
                    setCurrentPage(1);
                }}
            >
                <option value={0}>No Filter</option>
                <option value={NOT_PAID}>Not Paid</option>
                <option value={PAID_BUT_NOT_ANSWERED}>
                    Paid But Not Answered
                </option>
                <option value={PAID_BUT_NOT_UPLOADED}>
                    Paid But Not Uploaded
                </option>
                <option value={TO_BE_FILED}>To Be Filed</option>
                <option value={FILED}>Filed</option>
            </select>
            <select
                value={statusOfTaxFiler}
                onChange={(e) => {
                    setStatusOfTaxFiler(+e.target.value);
                    lastPage.current = null;
                    setCurrentPage(1);
                }}
            >
                <option value={0}>No Filter</option>
                <option value={INDIVIDUAL_TAX_FILER}>
                    Individual Tax Filer
                </option>
                <option value={LLC_TAX_FILER}>LLC Tax Filer</option>
            </select>
            <div>
                {currentPage !== 1 && (
                    <button
                        onClick={() => {
                            lastPage.current = currentPage;
                            setCurrentPage((prev) => prev - 1);
                        }}
                    >
                        Prev
                    </button>
                )}
                {currentPage !== totalPages && (
                    <button
                        onClick={() => {
                            lastPage.current = currentPage;
                            setCurrentPage((prev) => prev + 1);
                        }}
                    >
                        Next
                    </button>
                )}
            </div>
        </Container>
    );
};

export default TaxFilingRequestTable;
