import React, { useEffect } from "react";
import ContactDetails from "../../Components/ContactUs/ContactDetails/ContactDetails";
import ContactForm from "../../Components/ContactUs/ContactForm/ContactForm";
import { Container } from "./styles";

const ContactUs = () => {
    useEffect(() => {
        document.title = "Contact Us • Eazytaxes";
    }, []);
    return (
        <Container>
            <ContactDetails />
            <ContactForm />
        </Container>
    );
};

export default ContactUs;
