import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    border-radius: 2rem;
    background: #fff;
    padding: 2.5rem;
`;

export const Header = styled.div`
    position: relative;
    display: flex;
    height: 4.4rem;
    align-items: center;
`;

export const Heading = styled.div`
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    width: 100%;
`;

export const CloseButton = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    background: #ededed;
    padding: 0.8rem;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
    transition: 0.3s all;
    &:hover {
        border-color: #55bb53;
        color: #55bb53;
        background: rgba(85, 187, 83, 0.2);
    }
`;

export const Content = styled.div`
    margin: 2.5rem 0;
`;
