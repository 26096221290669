import React from "react";
import {
    Container,
    Content,
    ReviewContent,
    UserName,
    UserPic,
} from "./styles";
import { Rate } from 'antd';

const Review = ({ review }) => {
    const { name, profilePic, content, comment } = review;
    return (
        <Container>
            <UserPic
                src={`${process.env.PUBLIC_URL}/assets/testimonials/${profilePic}.jpeg`}
            >
            </UserPic>
            <Content>
                <Rate defaultValue={5} disabled={true} />
                <UserName>"{comment}"</UserName>
                <ReviewContent>“{content}”</ReviewContent>
                <UserName>{name}</UserName>
            </Content>
        </Container>
    );
};

export default Review;
