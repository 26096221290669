import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-areas:
        "sidebar main"
        "sidebar main";
    grid-template-columns: 30rem 1fr;
    height: 100vh;
`;

export const Main = styled.div`
    grid-area: main;
    padding: 2.5rem;
    height: calc(100vh - 10rem);
    overflow: auto;
`;
