import React from "react";
import "@lottiefiles/lottie-player";
import { Container, Heading, HeaderContainer } from "./styles";
import Button from "../../../Components/Global/Button/Button.jsx";

const AboutUsHero = () => {
    return (
        <Container>
            <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets7.lottiefiles.com/packages/lf20_wthp1bbd.json"
            ></lottie-player>
            <HeaderContainer>
            <Heading>
            <span>File your taxes without any hassle</span> from the
                comfort of your own home
            </Heading>
            <Button
                to="/get-my-taxes-filed"
                style={{ border: "0.1px solid #55bb53" }}
                padding="1.7rem 3.5rem"
            >
                Begin my tax filing process
            </Button>
            </HeaderContainer>

        </Container>
    );
};

export default AboutUsHero;
