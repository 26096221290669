import React, { useState } from "react";
import { Container, Tab, TabsGroup } from "./styles";

const Tabs = ({ data }) => {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <Container>
            <TabsGroup>
                {data.map(({ name }, index) => (
                    <Tab
                        key={index}
                        isActive={index === activeTab}
                        onClick={() => setActiveTab(index)}
                    >
                        {name}
                    </Tab>
                ))}
            </TabsGroup>
            {data[activeTab].component}
        </Container>
    );
};

export default Tabs;
