import React, { useContext, useState } from "react";
import { verifyAccount, resendOtp } from "../../Api/users.api";
import { errorToast, successToast } from "../../toasts.js";
import Button from "../../Components/Global/Button/Button";
import Input from "../../Components/Global/Input/Input";
import { AuthContext } from "../../Context/AuthContext";
import { Buttons, Container, Heading, ResendButton } from "./styles";
import { LoadingContext } from "../../Context/LoadingContext";

const VerifyAccount = () => {
    const [emailOtp, setEmailOtp] = useState("");
    const [user, refetchUserInfo] = useContext(AuthContext);
    const [isEmailOtpValid, setIsEmailOtpValid] = useState(false);
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const resendHandler = async () => {
        try {
            startApiCall();
            await resendOtp();
            successToast("OTP resent successfully on your registered email.");
        } catch (err) {
            errorToast("Something went wrong. Please try again later!");
        } finally {
            finishApiCall();
        }
    };
    const handleVerification = async () => {
        try {
            startApiCall();
            await verifyAccount(emailOtp);
            successToast("Account verified successfully.");
            refetchUserInfo();
        } catch (err) {
            errorToast("OTP is invalid.");
        } finally {
            finishApiCall();
        }
    };
    if (!user) return null;
    return (
        <Container>
            <Heading>Verify your account to continue!</Heading>
            <Input
                placeholder="Email Verification OTP"
                value={emailOtp}
                validationSchema={{
                    validateAsync: async (value) => {
                        if (value.length < 5) throw new Error();
                    },
                }}
                button={
                    <ResendButton onClick={() => resendHandler("email")}>
                        Resend
                    </ResendButton>
                }
                onValidation={(isValid) => setIsEmailOtpValid(isValid)}
                onChange={({ target }) =>
                    !isNaN(target.value) &&
                    target.value.length <= 5 &&
                    setEmailOtp(target.value)
                }
            />
            <Buttons>
                <Button
                    linkStyle={{
                        width: "100%",
                    }}
                    style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontSize: "1.8rem",
                    }}
                    isDisabled={!isEmailOtpValid}
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                    onClick={handleVerification}
                >
                    Verify Now
                </Button>
            </Buttons>
        </Container>
    );
};

export default VerifyAccount;
