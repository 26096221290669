import styled from "styled-components";

export const Wrapper = styled.div`
    flex-grow: 1;
`;

export const Label = styled.div`
    font-size: 2rem;
    font-weight: bold;
`;

export const Desc = styled.div`
    margin: 0.8rem 0;
    font-size: 1.4rem;
    color: #9092a3;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.2rem 2.4rem;
    border: 0.1rem solid #dbdbdb;
    border-radius: 0.3rem;
    transition: border 0.3s;
    ${({ inputFocused, isValid }) =>
        inputFocused ? `border-color: ${isValid ? "#55bb53" : "red"};` : ""}
    @media screen and (max-width: 500px) {
        padding: 1.2rem 2rem;
    }
`;

export const Content = styled.input`
    flex: 1;
    border: none;
    font-size: 1.6rem;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    color: #061e2f;
    flex-shrink: 0;
`;

export const Error = styled.div`
    color: red;
    margin-top: 0.5rem;
`;
