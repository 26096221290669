import styled from "styled-components";

export const Container = styled.div`
  padding: 3rem 3rem;
  margin-bottom: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 480px) {
    padding: 3rem 0rem;
 }
  `;

export const FeatureImage = styled.img`
    width: 35%;
    height: 17rem;
    padding: 1rem;
    margin: 1rem;
    @media screen and (max-width: 480px) {
        width: 40%;
        height: 13rem;
     }
     @media screen and (max-width: 820px) {
      width: 40%;
      height: 13rem;
   }
`;
