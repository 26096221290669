import React, { useContext, useEffect } from "react";
import {
    Buttons,
    Container,
    ForgotPassword,
    Heading,
    InputGroup,
    Or,
    OrLine,
} from "./styles";
import Input from "../../Components/Global/Input/Input";
import emailValidationSchema from "../../ValidationSchemas/emailValidationSchema";
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../Components/Global/Button/Button";
import { login } from "../../Api/users.api";
import { errorToast } from "../../toasts.js";
import { AuthContext } from "../../Context/AuthContext.js";
import useGoogleLoginSignup from "../../Hooks/useGoogleLoginSignup";
import { EMAIL_PASSWORD_LOGIN_SIGNUP_TYPE } from "../../data/constants";
import { LoadingContext } from "../../Context/LoadingContext";

const Login = () => {
    const [loginDetails, setLoginDetails] = useState({
        email: "",
        password: "",
    });
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const onLoginSuccess = async ({ data }) => {
        localStorage.setItem("authToken", JSON.stringify(data));
        refetchUserDetails();
        finishApiCall();
    };
    useGoogleLoginSignup({
        usedFor: "login",
        onSuccess: onLoginSuccess,
        onError: (err) => {
            errorToast(err);
            finishApiCall();
        },
    });
    const [, refetchUserDetails] = useContext(AuthContext);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const ShowHidePassword = React.cloneElement(
        isPasswordVisible ? <AiFillEyeInvisible /> : <AiFillEye />,
        {
            onClick: () => setIsPasswordVisible((prev) => !prev),
            fontSize: 25,
            style: {
                marginRight: "0.2rem",
                cursor: "pointer",
            },
        }
    );
    const handleLogin = async () => {
        try {
            startApiCall();
            const response = await login({
                loginType: EMAIL_PASSWORD_LOGIN_SIGNUP_TYPE,
                ...loginDetails,
            });
            onLoginSuccess(response);
        } catch (err) {
            errorToast(err.response.data.error.message);
            finishApiCall();
        }
    };
    useEffect(() => {
        document.title = "Login • Eazytaxes";
    }, []);
    return (
        <Container>
            <Heading>Hello Again!</Heading>
            <InputGroup>
                <Input
                    placeholder="Email"
                    icon={<AiOutlineMail />}
                    value={loginDetails.email}
                    validationSchema={emailValidationSchema}
                    onValidation={(isValid) => setIsEmailValid(isValid)}
                    onChange={({ target }) =>
                        setLoginDetails({
                            ...loginDetails,
                            email: target.value,
                        })
                    }
                />
                <Input
                    type={isPasswordVisible ? "text" : "password"}
                    button={ShowHidePassword}
                    placeholder="Password"
                    icon={<RiLockPasswordLine />}
                    value={loginDetails.password}
                    onChange={({ target }) =>
                        setLoginDetails({
                            ...loginDetails,
                            password: target.value,
                        })
                    }
                />
            </InputGroup>
            <ForgotPassword>
                <NavLink to="/accounts/forgot-password">
                    Forgot Password?
                </NavLink>
            </ForgotPassword>
            <Buttons>
                <Button
                    linkStyle={{
                        width: "100%",
                    }}
                    style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontSize: "1.8rem",
                    }}
                    isDisabled={
                        !isEmailValid ||
                        !loginDetails.email ||
                        !loginDetails.password
                    }
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                    onClick={handleLogin}
                >
                    Login
                </Button>
                <Or>
                    <OrLine />
                    OR
                    <OrLine />
                </Or>
                <div className="g_id_signin" />
            </Buttons>
        </Container>
    );
};

export default Login;
