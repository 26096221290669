import axios from "axios";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/users`,
    headers: {
        "Content-Type": "application/json",
    },
};

const users = axios.create(config);
const authenticatedUsers = axios.create(config);

authenticatedUsers.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            const accessToken = JSON.parse(authToken).accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

authenticatedUsers.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            !error.config.retry
        ) {
            error.config.retry = true;
            const accessToken = await refreshAccessToken();
            localStorage.setItem(
                "authToken",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("authToken")),
                    accessToken,
                })
            );
            return authenticatedUsers(error.config);
        }
        return Promise.reject(error);
    }
);

export const signUp = async (userData) => {
    return await users.post("/", userData);
};

export const login = async (loginData) => {
    return await users.post("/login", loginData);
};

export const validateForgotPasswordToken = async (token) =>
    await users.get(`/validateforgotpasswordtoken?token=${token}`);

export const sendForgotPasswordMail = async (data) => {
    return await users.post("/sendforgotpasswordmail", data);
};

export const forgotPassword = async (data) =>
    await users.patch("/forgotpassword", data);

export const logout = async () => {
    return await authenticatedUsers.delete("/");
};

export const userInfo = async () => {
    return await authenticatedUsers.get("/");
};

// export const resendOtp = async (sendFor) => {
//     return await authenticatedUsers.post(
//         `/resendverificationotp?sendFor=${sendFor}`
//     );
// };

export const resendOtp = async () => {
    return await authenticatedUsers.post(`/resendverificationotp`);
};

// export const verifyAccount = async (accountVerificationOtps) => {
//     return await authenticatedUsers.patch(
//         "/verifyaccount",
//         accountVerificationOtps
//     );
// };

export const verifyAccount = async (otp) => {
    return await authenticatedUsers.patch("/verifyaccount", { otp });
};

export const refreshAccessToken = async () => {
    const res = await users.post("/refreshAccessToken", {
        refreshToken: JSON.parse(localStorage.getItem("authToken"))
            .refreshToken,
    });
    return res.data.accessToken;
};
