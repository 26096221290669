import { useContext, useEffect } from "react";
import { login, signUp } from "../Api/users.api.js";
import { GOOGLE_LOGIN_SIGNUP_TYPE } from "../data/constants.js";
import { fileYourTaxes } from "../Api/filetaxes.api.js";
import { LoadingContext } from "../Context/LoadingContext.js";

const useGoogleLoginSignup = ({ usedFor, onSuccess, onError, fileTaxes }) => {
    const [, startApiCall] = useContext(LoadingContext);
    useEffect(() => {
        const interval = setInterval(() => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
                callback: async ({ credential }) => {
                    startApiCall();
                    try {
                        const config = {
                            token: credential,
                        };
                        let response;
                        if (fileTaxes) {
                            const taxFilingData =
                                sessionStorage.getItem("taxFilingData");
                            response = await fileYourTaxes({
                                ...JSON.parse(taxFilingData),
                                ...config,
                                signupType: GOOGLE_LOGIN_SIGNUP_TYPE,
                            });
                        } else {
                            response = await (usedFor === "login"
                                ? login({
                                      ...config,
                                      loginType: GOOGLE_LOGIN_SIGNUP_TYPE,
                                  })
                                : signUp({
                                      ...config,
                                      signupType: GOOGLE_LOGIN_SIGNUP_TYPE,
                                  }));
                        }
                        onSuccess(response);
                    } catch (err) {
                        onError(err.response.data.error.message);
                    }
                },
            });
            if (window.google) {
                clearInterval(interval);
                if (usedFor === "login") {
                    window.google.accounts.id.prompt();
                }
                window.google.accounts.id.renderButton(
                    document.querySelector(".g_id_signin"),
                    {
                        theme: "filled_blue",
                        text:
                            usedFor === "login" ? "signin_with" : "signup_with",
                        size: "large",
                        width: "250px",
                    }
                );
            }
        }, 200);
        return () => clearInterval(interval);
    }, [usedFor]);
};

export default useGoogleLoginSignup;
