import React from "react";
import {
  Container,
  Content,
  Desc,
  Heading,
  HeadingDesc,
  Image,
} from "./styles";
import "@lottiefiles/lottie-player";
import List from "Components/Global/List/List";
import { default as DataImages } from "Components/DataSecurity/DataSecurity";

const DataSecurity = () => {
  return (
    <Container>
      <Content>
        <HeadingDesc>
          <Heading>You And Your Data Is Safe With Us!</Heading>
          <Desc>
            <p>
              Your Information is Protected by Multiple Layers of Authentication
              and Advanced Security Measures:
            </p>

            <List
              items={[
                "Data is stored in dedicated secured servers by AWS in the USA, compliant with the IRS standards.",
                "Data is encrypted by 256-bit SSL facilitated by an Extended Validation SSL Certificate from a trusted certificate authority.",
                "Data is protected by a firewall and intrusion detection system.",
              ]}
            />
          </Desc>
        </HeadingDesc>
        <Image>
          <DataImages />
        </Image>
      </Content>
    </Container>
  );
};

export default DataSecurity;
