import React from "react";
import TaxFilingRequestTable from "../../../Components/Dashboard/Home/AdminHome/TaxFilingRequestTable/TaxFilingRequestTable";

const AdminHome = () => {
    return (
        <>
            <TaxFilingRequestTable />
        </>
    );
};

export default AdminHome;
