import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingContext } from "./LoadingContext";
import { userInfo } from "../Api/users.api";

export const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, startApiCall, finishApiCall] = useContext(LoadingContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const fetchUserInfo = () => {
        startApiCall();
        const authToken = JSON.parse(localStorage.getItem("authToken"));
        if (!authToken) {
            localStorage.removeItem("authToken");
            setUserDetails(null);
            finishApiCall();
            return;
        }
        userInfo()
            .then(({ data }) => {
                setUserDetails(data);
                finishApiCall();
            })
            .catch((err) => {
                finishApiCall();
            });
    };
    useEffect(() => {
        if (!loading) {
            if (userDetails) {
                if (pathname.startsWith("/dashboard")) {
                    if (!userDetails.email_verified) {
                        navigate("/accounts/verify");
                    }
                } else if (pathname.startsWith("/accounts")) {
                    if (pathname.startsWith("/accounts/verify")) {
                        if (userDetails.email_verified) navigate("/dashboard");
                    } else {
                        navigate("/dashboard");
                    }
                }
            } else {
                if (
                    pathname.startsWith("/dashboard") ||
                    pathname.startsWith("/accounts/verify")
                ) {
                    navigate("/");
                }
            }
        }
    }, [loading, userDetails, pathname, navigate]);
    useEffect(() => {
        fetchUserInfo();
    }, []);
    return (
        <AuthContext.Provider value={[userDetails, fetchUserInfo]}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
