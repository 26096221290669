import React from "react";
import { useRef } from "react";
import useFileDragAndDrop from "../../../Hooks/useFileDragAndDrop";
import {
    Desc,
    FileName,
    FilesSupported,
    FileUploadDesc,
    FileUploadZone,
    Label,
} from "./styles";

const DocumentUploadZone = ({ name, desc, file, setFile, style }) => {
    const fileUploadZoneRef = useRef(null);
    const { fileBeingDragged, fileDropError, browse } = useFileDragAndDrop({
        ref: fileUploadZoneRef,
        onChange: setFile,
        allowedTypes: ["application/pdf", "image/jpeg", "image/png"],
    });
    return (
        <div style={style}>
            {name && <Label>{name}</Label>}
            {desc && <Desc>{desc}</Desc>}
            <FileUploadZone
                ref={fileUploadZoneRef}
                fileBeingDragged={fileBeingDragged}
                fileDropError={fileDropError}
                profilePic={file}
            >
                {file && <FileName>{file.name}</FileName>}
                <FileUploadDesc>
                    Drop your file here, or <span onClick={browse}>browse</span>
                </FileUploadDesc>
                <FilesSupported>
                    Only PDF, JPG, JPEG and PNG file is supported.
                </FilesSupported>
            </FileUploadZone>
        </div>
    );
};

export default DocumentUploadZone;
