import React, { useEffect } from "react";
import termsAndConditionsParagraphs from "../../data/termsAndConditionParagraphs";
import {
    Container,
    Content,
    ContentHeading,
    ContentParagraph,
    Heading,
} from "./styles";

const TermsAndConditions = () => {
    useEffect(() => {
        document.title = "Terms & Conditions • Eazytaxes";
    }, []);
    return (
        <Container>
            <Heading>Terms & Conditions</Heading>
            <Content>
                <ContentHeading>Terms of Service</ContentHeading>
                {termsAndConditionsParagraphs.map((paragraph, index) => (
                    <ContentParagraph key={index}>{paragraph}</ContentParagraph>
                ))}
            </Content>
        </Container>
    );
};

export default TermsAndConditions;
