import axios from "axios";
import { refreshAccessToken } from "./users.api";

const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/generate-upload-presigned-url`,
    headers: {
        "Content-Type": "application/json",
    },
};

const authenticatedPreSignedUrl = axios.create(config);

authenticatedPreSignedUrl.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            const accessToken = JSON.parse(authToken).accessToken;
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

authenticatedPreSignedUrl.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            !error.config.retry
        ) {
            error.config.retry = true;
            const accessToken = await refreshAccessToken();
            localStorage.setItem(
                "authToken",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("authToken")),
                    accessToken,
                })
            );
            return authenticatedPreSignedUrl(error.config);
        }
        return Promise.reject(error);
    }
);

const generatePresignedUrls = async (data) =>
    await authenticatedPreSignedUrl.post("/", data);

const uploadFileFromPresignedUrl = async ({ upload_url, file, fileType }) =>
    await axios.put(upload_url, file, {
        headers: {
            "Content-Type": fileType,
            "x-amz-acl": "public-read",
        },
    });

export { generatePresignedUrls, uploadFileFromPresignedUrl };
