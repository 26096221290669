import styled from "styled-components";

export const FileUploadZone = styled.div`
    user-select: none;
    border: 0.2rem dashed #dbdbdb;
    border-radius: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    transition: all 0.2s;
    color: #60626d;
    ${({ fileDropError }) =>
        fileDropError
            ? `
                border-color: red;
                background: rgba(255, 0, 0, 0.3);
              `
            : ""}
    ${({ profilePic }) =>
        profilePic
            ? `
                border-color: #55bb53;
              `
            : ""}
    ${({ fileBeingDragged }) =>
        fileBeingDragged
            ? `
                border-color: #55bb53;
                background: rgba(85,187,83, 0.3);
              `
            : ""}
`;

export const FileName = styled.div``;

export const FileUploadDesc = styled.div`
    font-size: 1.5rem;
    margin: 1rem 0;
    & span {
        color: #55bb53;
        font-weight: 700;
        cursor: pointer;
    }
`;

export const FilesSupported = styled.div`
    font-size: 1.2rem;
`;

export const Label = styled.div`
    font-size: 2rem;
    font-weight: bold;
`;

export const Desc = styled.div`
    margin: 0.8rem 0;
    font-size: 1.4rem;
    color: #9092a3;
`;
