import React from "react";
import {
    Wrapper,
    Container,
    Header,
    Heading,
    CloseButton,
    Content,
} from "./styles";
import Button from "../Button/Button";
import { GrClose } from "react-icons/gr";

const Modal = ({
    children,
    style,
    heading,
    onClose,
    onSubmit,
    buttonText,
    buttonProps,
    showButton = true,
}) => {
    return (
        <Wrapper>
            <Container style={style}>
                <Header>
                    <Heading>{heading}</Heading>
                    <CloseButton onClick={onClose}>
                        <GrClose />
                    </CloseButton>
                </Header>
                <Content>{children}</Content>
                {showButton && (
                    <Button onClick={onSubmit} {...buttonProps}>
                        {buttonText}
                    </Button>
                )}
            </Container>
        </Wrapper>
    );
};

export default Modal;
