import React, { useContext, useEffect, useRef, useState } from "react";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineMail,
    AiOutlineUser,
} from "react-icons/ai";
import {
    RiCheckboxCircleFill,
    RiCloseCircleFill,
    RiLockPasswordLine,
} from "react-icons/ri";
import Input from "../../Components/Global/Input/Input";
import emailValidationSchema from "../../ValidationSchemas/emailValidationSchema";
import {
    Arrow,
    Buttons,
    Container,
    Heading,
    InputGroup,
    Or,
    OrLine,
    ToolTip,
} from "./styles";
import Button from "../../Components/Global/Button/Button";
import PhoneInput from "react-phone-number-input";
import CustomPhoneInput from "../../Components/Global/CustomPhoneInput/CustomPhoneInput";
import requiredValidationSchema from "../../ValidationSchemas/requiredValidationSchema";
import PasswordChecklist from "react-password-checklist";
import { createPopper } from "@popperjs/core";
import { signUp } from "../../Api/users.api";
import { fileYourTaxes } from "../../Api/filetaxes.api";
import { errorToast, successToast } from "../../toasts";
import { AuthContext } from "../../Context/AuthContext";
import { useSearchParams } from "react-router-dom";
import useGoogleLoginSignup from "../../Hooks/useGoogleLoginSignup";
import { LoadingContext } from "../../Context/LoadingContext";

const Signup = () => {
    const [searchParams] = useSearchParams();
    const fileTaxes = searchParams.get("action") === "filetaxes";
    const [signupDetails, setSignupDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNum: "",
    });
    const [, startApiCall, finishApiCall] = useContext(LoadingContext);
    const [, refetchUserDetails] = useContext(AuthContext);
    const [isVisible, setIsVisible] = useState(false);
    const passwordRef = useRef();
    const tooltipRef = useRef();
    const onSignupSuccess = ({ data }) => {
        successToast("Account created successfully!");
        sessionStorage.removeItem("taxFilingData");
        localStorage.setItem("authToken", JSON.stringify(data));
        refetchUserDetails();
        finishApiCall();
    };
    useEffect(() => {
        if (fileTaxes) {
            const taxFilingData = sessionStorage.getItem("taxFilingData");
            const { firstName, lastName, email, phoneNumber } =
                JSON.parse(taxFilingData);
            setSignupDetails({
                firstName,
                lastName,
                email,
                phoneNum: phoneNumber,
                password: "",
            });
            setIsEmailValid(true);
        }
    }, [fileTaxes]);
    useGoogleLoginSignup({
        usedFor: "signup",
        onSuccess: onSignupSuccess,
        onError: (err) => {
            errorToast(err);
            finishApiCall();
        },
        fileTaxes,
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const ShowHidePassword = React.cloneElement(
        isPasswordVisible ? <AiFillEyeInvisible /> : <AiFillEye />,
        {
            onClick: () => setIsPasswordVisible((prev) => !prev),
            fontSize: 25,
            style: {
                marginRight: "0.2rem",
                cursor: "pointer",
            },
        }
    );
    const handleSignup = async () => {
        try {
            startApiCall();
            let res = null;
            if (fileTaxes) {
                const taxFilingData = sessionStorage.getItem("taxFilingData");
                res = await fileYourTaxes({
                    ...JSON.parse(taxFilingData),
                    ...signupDetails,
                    signupType: 1,
                });
            } else {
                res = await signUp({
                    signupType: 1,
                    ...signupDetails,
                });
            }
            onSignupSuccess(res);
        } catch (err) {
            errorToast(err.response.data.error.message);
            finishApiCall();
        }
    };
    useEffect(() => {
        createPopper(passwordRef.current, tooltipRef.current, {
            placement: "bottom",
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 25],
                    },
                },
            ],
        });
        document.title = "Signup • Eazytaxes";
    }, []);
    return (
        <Container>
            <div
                id="g_id_onload"
                data-client_id="502505081365-2qsilc83nh7jb1sspfvlag5er4d6ru5f.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-callback="signupWithGoogle"
                data-auto_prompt="false"
            ></div>
            <Heading>Signup Now to start filing your returns</Heading>
            <InputGroup>
                <Input
                    placeholder="First Name"
                    icon={<AiOutlineUser />}
                    value={signupDetails.firstName}
                    validationSchema={requiredValidationSchema("First Name")}
                    onChange={({ target }) =>
                        setSignupDetails({
                            ...signupDetails,
                            firstName: target.value,
                        })
                    }
                />
                <Input
                    placeholder="Last Name"
                    icon={<AiOutlineUser />}
                    value={signupDetails.lastName}
                    validationSchema={requiredValidationSchema("Last Name")}
                    onChange={({ target }) =>
                        setSignupDetails({
                            ...signupDetails,
                            lastName: target.value,
                        })
                    }
                />
                <Input
                    placeholder="Email"
                    icon={<AiOutlineMail />}
                    validationSchema={emailValidationSchema}
                    value={signupDetails.email}
                    onValidation={setIsEmailValid}
                    onChange={({ target }) =>
                        setSignupDetails({
                            ...signupDetails,
                            email: target.value,
                        })
                    }
                />
                <PhoneInput
                    defaultCountry="US"
                    inputComponent={CustomPhoneInput}
                    value={signupDetails.phoneNum}
                    onChange={(value) =>
                        setSignupDetails({
                            ...signupDetails,
                            phoneNum: value,
                        })
                    }
                />
                <Input
                    ref={passwordRef}
                    onFocus={() => setIsVisible(true)}
                    onBlur={() => setIsVisible(false)}
                    type={isPasswordVisible ? "text" : "password"}
                    button={ShowHidePassword}
                    placeholder="Password"
                    icon={<RiLockPasswordLine />}
                    value={signupDetails.password}
                    onChange={({ target }) =>
                        setSignupDetails({
                            ...signupDetails,
                            password: target.value,
                        })
                    }
                />
                <ToolTip isVisible={isVisible} ref={tooltipRef} role="tooltip">
                    <PasswordChecklist
                        rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                        ]}
                        minLength={5}
                        value={signupDetails.password}
                        onChange={setIsPasswordValid}
                        iconComponents={{
                            ValidIcon: (
                                <RiCheckboxCircleFill
                                    color="green"
                                    fontSize={22}
                                />
                            ),
                            InvalidIcon: (
                                <RiCloseCircleFill color="red" fontSize={22} />
                            ),
                        }}
                    />
                    <Arrow isVisible={isVisible} data-popper-arrow />
                </ToolTip>
            </InputGroup>
            <Buttons>
                <Button
                    linkStyle={{
                        width: "100%",
                    }}
                    style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontSize: "1.8rem",
                    }}
                    onClick={handleSignup}
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                    isDisabled={
                        !isEmailValid ||
                        !isPasswordValid ||
                        !signupDetails.firstName ||
                        !signupDetails.lastName ||
                        !signupDetails.phoneNum
                    }
                >
                    Signup
                </Button>
                <Or>
                    <OrLine />
                    OR
                    <OrLine />
                </Or>
                <div className="g_id_signin" />
            </Buttons>
        </Container>
    );
};

export default Signup;
