const termsAndConditionsParagraphs = [
    "You, the individual whose name is printed below and is referred to herein as (“Client”, “you” or “your”), in exchange for services to be provided by eazytaxes.com LLC (“eazytaxes.com” or “we,” “our,” or “us”) agrees to the following terms and conditions (“Terms”):",
    "1. These Terms create a binding agreement between you and eazytaxes.com and govern any and all services provided to you by or through eazytaxes.com, whether through an electronic platform, by phone or in person (“Services”), subject to your compliance with all the terms, conditions, and notices contained or referenced herein. Please read these Terms carefully before purchasing or receiving any “Program” (as defined below). These Terms will govern all Services you receive from us. By electronically signing below, you acknowledge that you have read and understand your duties and responsibilities as outlined in these Terms and you accept and agree to be bound by these Terms. These Terms affect your legal rights and obligations, so if you do not agree to these Terms, do not sign below and do not receive any Services from eazytaxes.com. ",
    "2. Upon your payment of the price listed on this website for eazytaxes.com program you have selected (the “Payment”), eazytaxes.com shall provide access to the eazytaxes.com Online Course: The Entire Recruiting Cycle Landing the Offer (the “Program”) to you at the level selected online, subject to your compliance with all the terms, conditions, and notices contained or referenced herein. The Program includes access to career coaching content, including videos, resources, and templates. Additional resources and services are dependent upon which level you chose to purchase (i.e., Basic, Standard or Pro). Certain services may be available on a limited basis, including but not limited to one-on-one counseling sessions and may be subject to additional terms and conditions. You certify that you are eighteen (18) years of age or older and, if you are not an adult in the jurisdiction in which you reside (typically eighteen (18) years of age in United States), you have the consent and approval of your parent or legal guardian to receive the Services. In addition, you represent and warrant that you have the right, authority and capacity to enter into and abide by these Terms. ",
    "3. In consideration of the opportunity to access the Program, you agree to pay eazytaxes.com the Payment. You agree to make all payments to “eazytaxes.com” through electronic payment through a site designated by eazytaxes.com. All payments to eazytaxes.com are handled through a third-party website that is not operated or controlled by eazytaxes.com. Please read the terms and conditions of and payment options provided by, any such website carefully. Once you have been granted access to the Program materials, there are no returns or refunds. Notwithstanding this limitation, eazytaxes.com does offer a 30-Day Money-Back Guarantee for those whose thorough participation in the Program did not yield any “results” (as defined below) and who have completed each of the stets (a-d) set forth below. For the purposes of this Money-Back Guarantee, the Program has yielded “results” if any individual or entity that you contact as a part of your participation in the Program responds to you and/or sends you a Linkedin connection request. If you complete all steps as outlined in a-d below AND have not received any response or any connection requests from any such individuals or entities, you are eligible for a refund under this guarantee. In order to qualify for the Money-Back Guarantee, you must submit a request for a refund to hello@eazytaxes.com.com within 30 days of your purchase of the Program with proof that you have completed each of the following steps:",
    "a. You have watched every video included in the Program to 100% completion.",
    "b. You have completed every quiz at the end of each of the 6 sections of the Program with a 100% score. ",
    "c. You have completed each “Action Item” at the end of each of the 6 sections of the Program. ",
    "d. You have emailed hello@eazytaxes.com.com with a question about your situation that’s preventing you from receiving results.",
    "4. These Terms shall apply to any and all Services you receive from eazytaxes.com, including but not limited participation in the Program. eazytaxes.com may terminate our obligation to provide Services to you, and limit, suspend, or terminate Client’s participation in the Program without refund or forgiveness of monthly payments at any time, if we determine that such termination is in the best interest of eazytaxes.com, if Client becomes disruptive to eazytaxes.com or participants, Client fails to follow the Program guidelines, is difficult to work with, or impairs the participation of the other participants in the Program. In addition, and notwithstanding the foregoing provision, our obligation to perform the Services will terminate automatically, and no refund will be provided, if you fail to comply with any provision of these Terms or fail to adhere to our policies.",
    "5. While career coaching involves helping you identify and achieve your personal career goals, you understand and acknowledge that no result whatsoever is guaranteed by eazytaxes.com, including, but not limited to job offers, reached career goals, increased earnings, or any life change or improvement. You accept and agree that you are completely responsible for your progress and results from the Program. Company makes no representations, warranties or guarantees verbally or in writing. You understand that the statements “land your dream job” and “landing the offer” are marketing slogans and refer to the aspirations of our clients. It is in no way a promise, guarantee or representation about the results of our Services or any Program. You understand that because of the nature of the program and extent, the results experienced by each client may significantly vary. You further understand that there may be inherent risks associated with the advice and coaching given to you, and you voluntarily assume these risks and accept full responsibility for the outcomes and consequences of your own actions and choices that may be arise out of the Services. Program education and information is intended for a general audience and does not purport to be, nor should it be construed as, specific advice tailored to any individual. ",
    "6. All of the materials provided by eazytaxes.com are protected from copying under U.S. and international copyright laws and treatises. Any unauthorized copying, alteration, distribution, transmission, performance, display or other use of this material is prohibited. ",
    "7. eazytaxes.com shall treat all discussions as confidential, and, unless required to do so by law or because of circumstances that eazytaxes.com determines, in its sole an absolute discretion, is for the health, safety or welfare of you or another individual, eazytaxes.com shall not disclose any confidential information belonging to you, other than disclosures which might be made in the process of rendering the Services. You agree to treat any information shared by other Program participants as confidential. In addition, all information provided to you through the Program or through any other Services (“Proprietary Information”) is confidential, proprietary, and belongs solely and exclusively to eazytaxes.com. You understand and agree not to use or disclose such Proprietary Information in any manner other than for your personal use as contemplated by the Program. Proprietary Information shall not include information rightfully obtained from a third party. Further, by purchasing access to the Program, you agree that if you violate or display any likelihood of violating this section the Company will be entitled to injunctive relief to prohibit any such violations to protect against the harm of such violations.",
    "8. We are responsible only for performing the Services expressly provided for in these Terms. You agree to waive and release any and all present and future claims against us for negligence, loss, costs, or other damages to you as a result of the Services you receive, and we shall only be liable for gross negligence and intentional misconduct. We assume no responsibility for errors or omissions that may appear in any of the Program materials.",
    "9. In addition, and separate from the protections provided elsewhere in these Terms, to the extent that you have any valid claim or action against eazytaxes.com, you agree that our maximum aggregate liability to you shall not exceed the total amount you have paid to us for the Services. Further, if all or any part of these Terms shall be held invalid or unenforceable, it shall be enforced to the maximum extent permitted by law, and any portion that is not invalid or unenforceable shall, notwithstanding, continue in full legal force and effect. If we bring suit or use an attorney to collect, or enforce the payment of any Package Payments or other fees due to under these Terms, then you shall pay us all costs of collection and enforcement, including reasonable attorney’s fees and court costs in addition to any and all other amounts due.",
    "10. The foregoing provisions of Paragraphs 8 and 9 are for the benefit of eazytaxes.com, and these provisions shall constitute our entire liability and your sole and exclusive remedy, and shall survive any termination of these Terms.",
    "11. You agree to indemnify eazytaxes.com from and against any loss or damage, including reasonable attorney fees and disbursements, caused by or arising out of any breach or alleged breach of any representation made by you herein.",
    "12. You represent and warrant that you have the power and the legal authority to accept and agree to these Terms on behalf of yourself.",
    "13. These Terms and your and our obligations hereunder shall be governed, construed and interpreted by, through and under the Laws of the State of California, without regard for conflict of laws. Any controversy or claim relating to these Terms and/or the Services shall be settled by binding arbitration. Any arbitration arising out of these Terms or the Services shall be submitted the American Arbitration Association pursuant to American Arbitration Rules and Procedures and judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction. For all matters arising out of or relating to these Terms or out of the Services, you agree to submit to the jurisdiction of the State of California. Any arbitration proceeding shall take place and the arbitration award given in writing in Los Angeles County, California.  ",
    "14. Other than as specifically provided in these Terms, these Terms may only be amended, waived, or modified by a writing referencing these Terms and signed by eazytaxes.com and accepted by you. No waiver of any of the provisions in these Terms shall be deemed, or shall constitute, a waiver of any other provision of these Terms (whether or not similar), nor shall any such waiver constitute a continuing waiver unless otherwise expressly provided. These Terms constitute the entire agreement between us and you, and there are no other prior or contemporaneous written or oral agreements, understandings, promises, discussions, warranties or covenants not specifically referred to or contained in these Terms. Any rights you have to the Services and to any sessions are not assignable or transferable by you. You may not provide anyone else with access to the Program or to any of the Services. eazytaxes.com has the right to freely assign its rights and obligations under these Terms.  ",
    "15. Any notice to you given in relation or pursuant to these Terms shall be in writing and shall be delivered personally, by electronic mail, addressed using the information you have provided to us when you register for Services. Service of any such communication shall be deemed made on the date of actual receipt at such address. You or we may, from time to time, by notice in writing served upon the other party as aforesaid, designate a different electronic mail address, different person, or reasonable additional persons to whom all communications are thereafter to be made.",
    "16. Your agreement to and acceptance of these Terms may be executed by providing an electronic signature under the terms of the Electronic Signatures Act, 15 U.S.C. SS 7001 et. seq. and/or the Uniform Electronic Transactions Act, California Civil Code §§1633.1 et. seq., and may not be denied legal effect solely because it is in electronic form or permits the completion of the business transaction referenced herein electronically instead of in person. You agree that any electronic signature you provide shall be as valid as an original signature shall be effective to bind you to these Terms. eazytaxes.com shall be entitled to rely on your electronic signature to authenticate this writing and to have the same force and effect as a manual signature.",
    "17. You understand and agree that this document is a legal and binding agreement on you and your family, spouse, and heirs, enforceable in accordance with its terms, and if you have any questions about any part of these Terms, you will have it reviewed by an attorney before you sign it. ",
    "By accepting these Terms you agree to be bound to all of the provisions contained in these Terms.   ",
];

export default termsAndConditionsParagraphs;
