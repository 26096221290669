import { useContext, useEffect, useState } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import Footer from "./Components/Global/Footer/Footer";
import Header from "./Components/Global/Header/Header";
import Loading from "./Components/Global/Loading/Loading";
import { LoadingContext } from "./Context/LoadingContext";
import Accounts from "./Layout/Accounts/Accounts";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Pricing from "./Pages/Pricing/Pricing";
import ContactUs from "./Pages/ContactUs/ContactUs";
import GetMyTaxesFiled from "./Pages/GetMyTaxesFiled/GetMyTaxesFiled";
import Home from "./Pages/Home/Home";
import ForgotPassword from "./Pages/Accounts/ForgotPassword";
import Login from "./Pages/Accounts/Login";
import ResetPassword from "./Pages/Accounts/ResetPassword";
import Signup from "./Pages/Accounts/Signup";
import VerifyAccount from "./Pages/Accounts/VerifyAccount";
import Blogs from "./Pages/Blogs/Blogs";
import Dashboard from "./Layout/Dashboard";
import Services from "./Pages/Services/Services";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import DashboardHome from "./Pages/Dashboard/Home/Home";
import HamMenu from "./Components/Global/HamMenu/HamMenu";
import Testimonials from "./Pages/Testimonials/Testimonials";
import PaymentSuccessfull from "./Pages/Dashboard/PaymentSuccessfull/PaymentSuccessfull";
import PaymentFailed from "./Pages/Dashboard/PaymentFailed/PaymentFailed";
import AdminHome from "./Pages/Dashboard/Home/AdminHome";
import TaxFilingQuestions from "./Pages/Dashboard/TaxFilingQuestions/TaxFilingQuestions";
import TaxFilingDocuments from "./Pages/Dashboard/TaxFilingDocuments/TaxFilingDocuments";
import Salaried from "./Pages/Services/Service/Salaried";
import FBASellers from "./Pages/Services/Service/FBASellers";
import SmallBusiness from "./Pages/Services/Service/SmallBuisness";
import Students from "./Pages/Services/Service/Students";

function App() {
    const [isHamMenuVisible, setIsHamMenuVisible] = useState();
    const { pathname } = useLocation();
    const [loading] = useContext(LoadingContext);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [pathname]);
    useEffect(() => {
        if (isHamMenuVisible) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
    }, [isHamMenuVisible]);
    return (
        <>
            {loading && <Loading />}
            {!pathname.startsWith("/accounts") &&
                (
                    <Header openHamMenu={() => setIsHamMenuVisible(true)} />
                )}
            {isHamMenuVisible && (
                <HamMenu closeHamMenu={() => setIsHamMenuVisible(false)} />
            )}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/pricing" element={<Pricing />} />

                <Route
                    path="/services/salaried-professional"
                    element={<Salaried />}
                />
                <Route
                    path="/services/ebay-shopify-amazon-sellers"
                    element={<FBASellers />}
                />
                <Route
                    path="/services/small-business-owners"
                    element={<SmallBusiness />}
                />
                <Route path="/services/students" element={<Students />} />

                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                />
                <Route
                    path="/get-my-taxes-filed"
                    element={<GetMyTaxesFiled />}
                />
                <Route path="/accounts" element={<Accounts />}>
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="verify" element={<VerifyAccount />} />
                </Route>
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<DashboardHome />} />
                    <Route path="questions" element={<TaxFilingQuestions />} />
                    <Route path="documents" element={<TaxFilingDocuments />} />
                    <Route
                        path="payment-successfull"
                        element={<PaymentSuccessfull />}
                    />
                    <Route path="payment-failed" element={<PaymentFailed />} />
                </Route>
            </Routes>
            {!pathname.startsWith("/accounts") &&
                !pathname.startsWith("/dashboard") && <Footer />}
        </>
    );
}

export default App;
